import React from 'react';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';

import { ForgetPasswordParams } from '../interface/company';
import ForgetPasswordForm from '../component/forgetPasswordForm';

interface UIState {
	isLoading: boolean,
	isSuccessfull: boolean;
	// message: string;
}

class ForgetPassword extends React.Component {
	state: UIState = {
		isLoading: false,
		isSuccessfull: false,
		// message: ''
	}

	render() {
		const { isLoading, isSuccessfull } = this.state;
		return (
			<div className='gray-bg phoenix-password-wrapper forget-password-wrapper phoenix-background-svg'>
				<ForgetPasswordForm
					forgetPassword={this.forgetPassword}
					isLoading={isLoading}
					isSuccessful={isSuccessfull}
				/>
			</div>
		)
	}

	forgetPassword = (data: ForgetPasswordParams) => {
		this.setState({ isLoading: true })
		HttpService.post(API_CONFIG.path.phoenixForgotPassword, data).then((response) => {
			this.setState({ isLoading: false, isSuccessfull: true });
		}).catch((err: Error) => {
			this.setState({ isLoading: false });
		});
	}
}

export default ForgetPassword;

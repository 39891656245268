import { lazy } from 'react';
import { withRouter } from 'react-router';

const SuperAdminOrganization = withRouter(lazy(() => import('features/organizations/container/orgSuperAdmin')));
const LicenseOrganization = withRouter(lazy(() => import('features/organizations/container/orgLicense')));
const SupportOrganization = withRouter(lazy(() => import('features/organizations/container/orgSupportExtended')));
const OrganizationIncomingLicenses = lazy(() => import('features/organizations/orgIncomingLicenses/container/orgIncomingLicenses'));
const OrganizationIncomingLicenseDetail = withRouter(lazy(() => import('features/organizations/orgIncomingLicenseDetail/container/orgIncomingLicenseDetail')));
const PhoenixIncomingLicenses = lazy(() => import('features/organizations/phoenixLicenses/container/phoenixLicenses'));
const JumpbirdIncomingLicenses = lazy(() => import('features/organizations/jumpbirdLicenses/container/jumpbirdLicenses'));

const SuperAdminOrgDetail = withRouter(lazy(() => import('features/organizationDetail/container/superAdminOrgDetail')));
const SupportAdminOrgDetail = withRouter(lazy(() => import('features/organizationDetail/container/supportOrgDetail')));
const LicenseAdminOrgDetail = withRouter(lazy(() => import('features/organizationDetail/container/licenseOrgDetail')));
const CustomerLicence = withRouter(lazy(() => import('features/customer-license/container/customerLicense')));
const LicenseModuleList = withRouter(lazy(() => import('features/licenseModule/container/moduleList')));

const EmailTemplates = lazy(() => import('features/email-templates/container/emailTemplates'));
const EditEmailTemplate = withRouter(lazy(() => import('features/edit-email-template/container/editEmailTemplate')));

const Turnover = lazy(() => import('features/turnoverStatistics/container/turnover'));

const ReaList = lazy(() => import('features/rea/container/reaList'));

const ReportList = lazy(() => import('features/provision/container/report'));
const ReportDetail = withRouter(lazy(() => import('features/provision/container/reportDetail')));

const InvoiceGroupList = lazy(() => import('features/invoices/container/invoiceGroup'));
const InvoiceGroupDetail = withRouter(lazy(() => import('features/invoices/container/invoiceGroupDetail')));

const InventoriesList = withRouter(lazy(() => import('features/inventory/inventories/container/inventoriesList')));


const InventoryGroupList = lazy(() => import('features/inventory/inventoryGroup/container/inventoryGroup'));

const TaxRulesList = lazy(() => import('features/taxRules/container/taxRulesList'));
const CustomerList = lazy(() => import('features/documents/container/customerList'));
const DocumentList = lazy(() => import('features/documents/container/documentList'));

const IncomingLicenses = lazy(() => import('features/licenseService/incomingLicenses/container/incomingLicenses'));
const MachineList = lazy(() => import('features/licenseService/machineList/container/machineList'));
const AutoUpdateMachineList = lazy(() => import('features/licenseService/autoUpdateMachineList/container/machineList'));
const LicenseNumbers = withRouter(lazy(() => import('features/licenseService/licenseNumbers/container/licenseNumbers')));
const OrderCodeExtensions = lazy(() => import('features/licenseService/orderCodeExtension/container/orderCodeExtensionsList'));
const ConfigurationsList = lazy(() => import('features/licenseService/configuration/container/configuration'));
const LicenseDecoder = lazy(() => import('features/licenseService/licenseDecoder/container/licenseDecoder'));

const Dashboard = withRouter(lazy(() => import('features/dashboard/component/dashboard')));

const Users = lazy(() => import('features/users/container/users'));
const AddUser = withRouter(lazy(() => import('features/user-actions/container/addUser')));
const EditUser = withRouter(lazy(() => import('features/user-actions/container/editUser')));

const Permissions = lazy(() => import('features/permissions/container/permissions'));
const Roles = lazy(() => import('features/roles/container/roles'));

const Credential = withRouter(lazy(() => import('features/birdhub/credential/container/credential')));
const ClientsList = withRouter(lazy(() => import('features/birdhub/ordersList/container/clients')));
const OrdersList = withRouter(lazy(() => import('features/birdhub/ordersList/container/orders')));
const OrderDetail = withRouter(lazy(() => import('features/birdhub/ordersList/container/orderDetail')));

const Company = lazy(() => import('features/phoenix/company/container/company'));
const CompanyDetail = withRouter(lazy(() => import('features/phoenix/company/container/companyDetail')));

const ModuleList = withRouter(lazy(() => import('features/phoenix/module/container/moduleList')));
const PackageList = withRouter(lazy(() => import('features/phoenix/package/container/packageList')));
const ApiKeyList = lazy(() => import('features/phoenix/apiKeyList/container/apiKeyList'));

const JumpbirdCompany = lazy(() => import('features/jumpbird/company/container/company'));
const JumpbirdCompanyDetail = withRouter(lazy(() => import('features/jumpbird/company/container/companyDetail')));
const JumpbirdPackageList = withRouter(lazy(() => import('features/jumpbird/package/container/packageList')));

const PbpwList = lazy(() => import('features/pbpw/container/pbpw'));

const TSEDashboard = lazy(() => import('features/tseDashboard/container/tseDashboard'));
const TSEDeviceList = lazy(() => import('features/tseDevice/container/tseDevices'));
const TSEDeviceActivityLog = withRouter(lazy(() => import('features/tseDevice/container/tseDeviceActivityLog')));
const TSECustomerList = lazy(() => import('features/tseCustomer/container/tseCustomer'));
const TSECustomerListV2 = lazy(() => import('features/tseCustomer/container/tseCustomerV2'));
const TSECustomerDetail = withRouter(lazy(() => import('features/tseCustomer/container/tseCustomerDetail')));

const ResigoMachine = lazy(() => import('features/resigo/machine/container/machine'));
const ResigoLicense = lazy(() => import('features/resigo/license/container/license'));
const ResigoMachineDetail = withRouter(lazy(() => import('features/resigo/machine/container/machineDetail')));

const PosbillBackupDashboard = lazy(() => import('features/posbillBackup/dashboard/container/dashboard'));
const PosbillBackupCustomerList = lazy(() => import('features/posbillBackup/customers/container/posbillBackupCustomer'));
const PosbillBackupDownloadRequestList = lazy(() => import('features/posbillBackup/customers/container/posbillBackupDownloadRequest'));
const PosbillBackupCustomerDetail = withRouter(lazy(() => import('features/posbillBackup/customers/container/posbillBackupCustomerDetail')));

const ZMList = lazy(() => import('features/tools/container/zmList'));
export {
	SuperAdminOrganization, LicenseOrganization, SupportOrganization,

	OrganizationIncomingLicenses, OrganizationIncomingLicenseDetail, PhoenixIncomingLicenses, JumpbirdIncomingLicenses,

	SuperAdminOrgDetail, SupportAdminOrgDetail, LicenseAdminOrgDetail, CustomerLicence, LicenseModuleList,

	EmailTemplates, EditEmailTemplate,

	Turnover,

	ReaList,

	ReportList, ReportDetail,

	InvoiceGroupList, InvoiceGroupDetail, InventoryGroupList, InventoriesList, TaxRulesList, CustomerList, DocumentList,

	IncomingLicenses, MachineList, AutoUpdateMachineList, LicenseNumbers, OrderCodeExtensions, ConfigurationsList, LicenseDecoder,

	Dashboard,

	Users, AddUser, EditUser,

	Permissions, Roles,

	Credential, ClientsList, OrdersList, OrderDetail,

	Company, CompanyDetail,

	ModuleList, PackageList, ApiKeyList,

	JumpbirdCompany, JumpbirdCompanyDetail, JumpbirdPackageList,

	TSEDeviceList, TSEDeviceActivityLog, TSECustomerList, TSECustomerListV2, TSECustomerDetail, TSEDashboard,

	PosbillBackupDashboard, PosbillBackupCustomerList, PosbillBackupDownloadRequestList, PosbillBackupCustomerDetail,

	ResigoMachine, ResigoMachineDetail, ResigoLicense,

	ZMList, PbpwList
}

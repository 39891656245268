import React from 'react';
import { checkPermission, AllPermissionRequired, AnyOnePermissionRequired } from 'shared/util/permission';

/**
 * PermissionManager - render children based on permissions
 * @param permission : number of ALL permissions to check before rendering
 * @param anyOnePermission : number of ANY OF GIVEN permissions to check before rendering
 */
const PermissionManager: React.FC<AllPermissionRequired | AnyOnePermissionRequired> = (props) => {
	const doesHavePermission = checkPermission(props);
	if (!doesHavePermission) {
		return null;
	}
	return (
		<>{props.children}</>
	)
}

export default PermissionManager;

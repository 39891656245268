import React from 'react';
import AuthService from 'shared/services/auth.service';


const environment = process.env.NODE_ENV;

const slackBasicUrl = 'https://hooks.slack.com/services/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';

const userData = AuthService.getUserData();

let userEmail = 'UNKNOWN USER';
if (userData && userData.user) {
	userEmail = userData.user.email;
}

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = (error:any) => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
  ) as boolean;

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
		
		// Send message to slack ///////////////////////////////////////////////////
		const slackMessage = `Universe-core-frontend (${environment}): ${error} # user: ${userEmail}`;
		
		const xhr = new XMLHttpRequest();
		xhr.open('POST', slackBasicUrl, true);
		xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
		xhr.send(JSON.stringify({
		    text: slackMessage,
		}));

		console.log('MESSAGE SENT TO SLACK: ', slackMessage);
		////////////////////////////////////////////////////////////////////////////
		
		setTimeout(() => {
			return window.location.reload();
		}, 3000);
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
  }
};


class ErrorBoundary extends React.Component {
	state = {
		error: null,
		info: null,
	};
	static getDerivedStateFromError(error: Error | null) {
		return { error };
	}

	// componentDidMount() {
	// 	//window.onerror = this.logError;
	// }
	
	componentDidCatch(error: Error | null, info: object) {
		retryPageLoading(error);
    console.log(error, info);
		// this.logError(error);
		this.setState({info: info});
	}

	render() {
		if (this.state.error) {
			
			return (
				<div style={{maxWidth: '800px', margin: 'auto', padding: '100px 20px', textAlign: 'center'}}>
					<h1>Die Applikation hat Fehler</h1>
					<br/>
					<p>Nach 3 Sekunden lädt sich die Seite erneut.</p>
					<br/>
					<br/>
					<p>Fehler:</p>
					<pre>{JSON.stringify(this.state.error)}</pre>
					<br/>
					<p>Info:</p>
					<pre>{JSON.stringify(this.state.info)}</pre>
				</div>
			);
		}
		return this.props.children;
	}

	// logError(args: Error | null) {
	// 	console.log(args);
	// }
}

export default ErrorBoundary;

export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const GET_PROFILE_INIT = 'GET_PROFILE_INIT';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const FORGET_PASSWORD_INIT = 'FORGET_PASSWORD_INIT';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const AUTH_LOGOUT_INIT = 'AUTH_LOGOUT_INIT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

export const FETCH_USERS_INIT = 'FETCH_USERS_INIT';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_ARCHIVED_USERS_INIT = 'FETCH_ARCHIVED_USERS_INIT';
export const FETCH_ARCHIVED_USERS_SUCCESS = 'FETCH_ARCHIVED_USERS_SUCCESS';
export const FETCH_ARCHIVED_USERS_FAIL = 'FETCH_ARCHIVED_USERS_FAIL';

export const FETCH_USER_INIT = 'FETCH_USER_INIT';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const ADD_USER_INIT = 'ADD_USER_INIT';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const EDIT_USER_INIT = 'EDIT_USER_INIT';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

export const DELETE_USER_INIT = 'DELETE_USER_INIT';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const RESTORE_USER_INIT = 'RESTORE_USER_INIT';
export const RESTORE_USER_SUCCESS = 'RESTORE_USER_SUCCESS';
export const RESTORE_USER_FAIL = 'RESTORE_USER_FAIL';

export const FETCH_ORGANIZATIONS_INIT = 'FETCH_ORGANIZATIONS_INIT';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_FAIL = 'FETCH_ORGANIZATIONS_FAIL';

export const FETCH_ORGANIZATION_INIT = 'FETCH_ORGANIZATION_INIT';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_FAIL = 'FETCH_ORGANIZATION_FAIL';

export const FETCH_ORGANIZATION_LABELS_INIT = 'FETCH_ORGANIZATION_LABELS_INIT';
export const FETCH_ORGANIZATION_LABELS_SUCCESS = 'FETCH_ORGANIZATION_LABELS_SUCCESS';
export const FETCH_ORGANIZATION_LABELS_FAIL = 'FETCH_ORGANIZATION_LABELS_FAIL';

export const FETCH_ORGANIZATION_TIMELINE_INIT = 'FETCH_ORGANIZATION_TIMELINE_INIT';
export const FETCH_ORGANIZATION_TIMELINE_SUCCESS = 'FETCH_ORGANIZATION_TIMELINE_SUCCESS';
export const FETCH_ORGANIZATION_TIMELINE_FAIL = 'FETCH_ORGANIZATION_TIMELINE_FAIL';

export const FETCH_PERMISSIONS_INIT = 'FETCH_PERMISSIONS_INIT';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL';

export const FETCH_PERMISSIONS_BY_MODULE_INIT = 'FETCH_PERMISSIONS_BY_MODULE_INIT';
export const FETCH_PERMISSIONS_BY_MODULE_SUCCESS = 'FETCH_PERMISSIONS_BY_MODULE_SUCCESS';
export const FETCH_PERMISSIONS_BY_MODULE_FAIL = 'FETCH_PERMISSIONS_BY_MODULE_FAIL';

export const FETCH_EMAIL_TEMPLATES_INIT = 'FETCH_EMAIL_TEMPLATES_INIT';
export const FETCH_EMAIL_TEMPLATES_SUCCESS = 'FETCH_EMAIL_TEMPLATES_SUCCESS';
export const FETCH_EMAIL_TEMPLATES_FAIL = 'FETCH_EMAIL_TEMPLATES_FAIL';

export const FETCH_EMAIL_TEMPLATE_INIT = 'FETCH_EMAIL_TEMPLATE_INIT';
export const FETCH_EMAIL_TEMPLATE_SUCCESS = 'FETCH_EMAIL_TEMPLATE_SUCCESS';
export const FETCH_EMAIL_TEMPLATE_FAIL = 'FETCH_EMAIL_TEMPLATE_FAIL';

export const EDIT_EMAIL_TEMPLATE_INIT = 'EDIT_EMAIL_TEMPLATE_INIT';
export const EDIT_EMAIL_TEMPLATE_SUCCESS = 'EDIT_EMAIL_TEMPLATE_SUCCESS';
export const EDIT_EMAIL_TEMPLATE_FAIL = 'EDIT_EMAIL_TEMPLATE_FAIL';

export const FETCH_ROLES_INIT = 'FETCH_ROLES_INIT';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';

export const ADD_ROLE_INIT = 'ADD_ROLE_INIT';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAIL = 'ADD_ROLE_FAIL';

export const EDIT_ROLE_INIT = 'EDIT_ROLE_INIT';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAIL = 'EDIT_ROLE_FAIL';

export const REMOVE_ROLE_INIT = 'REMOVE_ROLE_INIT';
export const REMOVE_ROLE_SUCCESS = 'REMOVE_ROLE_SUCCESS';
export const REMOVE_ROLE_FAIL = 'REMOVE_ROLE_FAIL';

export const EMPTY_LICENSES = 'EMPTY_LICENSES';
export const FETCH_LICENSE_INIT = 'FETCH_LICENSE_INIT';
export const FETCH_LICENSE_SUCCESS = 'FETCH_LICENSE_SUCCESS';
export const FETCH_LICENSE_FAIL = 'FETCH_LICENSE_FAIL';

export const FETCH_MACHINE_LICENSE_INIT = 'FETCH_MACHINE_LICENSE_INIT';
export const FETCH_MACHINE_LICENSE_SUCCESS = 'FETCH_MACHINE_LICENSE_SUCCESS';
export const FETCH_MACHINE_LICENSE_FAIL = 'FETCH_MACHINE_LICENSE_FAIL';

export const UPDATE_LICENSE_INIT = 'UPDATE_LICENSE_INIT';
export const UPDATE_LICENSE_SUCCESS = 'UPDATE_LICENSE_SUCCESS';
export const UPDATE_LICENSE_FAIL = 'UPDATE_LICENSE_FAIL';

export const SEND_LICENSE_EMAIL_INIT = 'SEND_LICENSE_EMAIL_INIT';
export const SEND_LICENSE_EMAIL_SUCCESS = 'SEND_LICENSE_EMAIL_SUCCESS';
export const SEND_LICENSE_EMAIL_FAIL = 'SEND_LICENSE_EMAIL_FAIL';

export const ADD_CUSTOMER_LICENSE_WITH_EMAIL_INIT = 'ADD_CUSTOMER_LICENSE_WITH_EMAIL_INIT';
export const ADD_CUSTOMER_LICENSE_WITH_EMAIL_SUCCESS = 'ADD_CUSTOMER_LICENSE_WITH_EMAIL_SUCCESS';
export const ADD_CUSTOMER_LICENSE_WITH_EMAIL_FAIL = 'ADD_CUSTOMER_LICENSE_WITH_EMAIL_FAIL';

export const GET_CUSTOMER_LICENSE_MODULE_INIT = 'GET_CUSTOMER_LICENSE_MODULE_INIT';
export const GET_CUSTOMER_LICENSE_MODULE_SUCCESS = 'GET_CUSTOMER_LICENSE_MODULE_SUCCESS';
export const GET_CUSTOMER_LICENSE_MODULE_FAIL = 'GET_CUSTOMER_LICENSE_MODULE_FAIL';

export const MANAGE_MODULES_INIT = 'MANAGE_MODULES_INIT';
export const MANAGE_MODULES_SUCCESS = 'MANAGE_MODULES_SUCCESS';
export const MANAGE_MODULES_FAIL = 'MANAGE_MODULES_FAIL';

export const CHANGE_COMPANY_LOCK_STATUS_INIT = 'CHANGE_COMPANY_LOCK_STATUS_INIT'
export const CHANGE_COMPANY_LOCK_STATUS_SUCCESS = 'CHANGE_COMPANY_LOCK_STATUS_SUCCESS';
export const CHANGE_COMPANY_LOCK_STATUS_FAIL = 'CHANGE_COMPANY_LOCK_STATUS_FAIL';

export const SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_INIT = 'SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_INIT'
export const SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_SUCCESS = 'SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_SUCCESS';
export const SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_FAIL = 'SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_FAIL';

export const GET_VALID_LICENSE_INIT = 'GET_VALID_LICENSE_INIT'
export const GET_VALID_LICENSE_SUCCESS = 'GET_VALID_LICENSE_SUCCESS';
export const GET_VALID_LICENSE_FAIL = 'GET_VALID_LICENSE_FAIL';

export const ACTIVATE_FUTURE_LICENSE_INIT = 'ACTIVATE_FUTURE_LICENSE_INIT'
export const ACTIVATE_FUTURE_LICENSE_SUCCESS = 'ACTIVATE_FUTURE_LICENSE_SUCCESS';
export const ACTIVATE_FUTURE_LICENSE_FAIL = 'ACTIVATE_FUTURE_LICENSE_FAIL';

export const DELETE_LICENSE_INIT = 'DELETE_LICENSE_INIT'
export const DELETE_LICENSE_SUCCESS = 'DELETE_LICENSE_SUCCESS';
export const DELETE_LICENSE_FAIL = 'DELETE_LICENSE_FAIL';

export const CHANGE_MASTER_PASSWORD_INIT = 'CHANGE_MASTER_PASSWORD_INIT'
export const CHANGE_MASTER_PASSWORD_SUCCESS = 'CHANGE_MASTER_PASSWORD_SUCCESS';
export const CHANGE_MASTER_PASSWORD_FAIL = 'CHANGE_MASTER_PASSWORD_FAIL';

export const MANAGE_PHOENIX_SUBSCRIPTION_INIT = 'MANAGE_PHOENIX_SUBSCRIPTION_INIT';
export const MANAGE_PHOENIX_SUBSCRIPTION_SUCCESS = 'MANAGE_PHOENIX_SUBSCRIPTION_SUCCESS';
export const MANAGE_PHOENIX_SUBSCRIPTION_FAIL = 'MANAGE_PHOENIX_SUBSCRIPTION_FAIL';

export const MANAGE_PHOENIX_MODULES_INIT = 'MANAGE_PHOENIX_MODULES_INIT';
export const MANAGE_PHOENIX_MODULES_SUCCESS = 'MANAGE_PHOENIX_MODULES_SUCCESS';
export const MANAGE_PHOENIX_MODULES_FAIL = 'MANAGE_PHOENIX_MODULES_FAIL';

export const ADD_HARDWARE_INIT = 'ADD_HARDWARE_INIT';
export const ADD_HARDWARE_SUCCESS = 'ADD_HARDWARE_SUCCESS';
export const ADD_HARDWARE_FAIL = 'ADD_HARDWARE_FAIL';

export const ADD_HARDWARE_WITH_EMAIL_INIT = 'ADD_HARDWARE_WITH_EMAIL_INIT';
export const ADD_HARDWARE_WITH_EMAIL_SUCCESS = 'ADD_HARDWARE_WITH_EMAIL_SUCCESS';
export const ADD_HARDWARE_WITH_EMAIL_FAIL = 'ADD_HARDWARE_WITH_EMAIL_FAIL';

export const ADD_CUSTOMER_LICENSE_INIT = 'ADD_CUSTOMER_LICENSE_INIT';
export const ADD_CUSTOMER_LICENSE_SUCCESS = 'ADD_CUSTOMER_LICENSE_SUCCESS';
export const ADD_CUSTOMER_LICENSE_FAIL = 'ADD_CUSTOMER_LICENSE_FAIL';

export const FETCH_INCOMING_LICENSES_INIT = 'FETCH_INCOMING_LICENSES_INIT';
export const FETCH_INCOMING_LICENSES_SUCCESS = 'FETCH_INCOMING_LICENSES_SUCCESS';
export const FETCH_INCOMING_LICENSES_FAIL = 'FETCH_INCOMING_LICENSES_FAIL';

export const FETCH_ORG_INCOMING_LICENSES_INIT = 'FETCH_ORG_INCOMING_LICENSES_INIT';
export const FETCH_ORG_INCOMING_LICENSES_SUCCESS = 'FETCH_ORG_INCOMING_LICENSES_SUCCESS';
export const FETCH_ORG_INCOMING_LICENSES_FAIL = 'FETCH_ORG_INCOMING_LICENSES_FAIL';

export const SEARCH_CUSTOMER_TO_RELATE_INIT = 'SEARCH_CUSTOMER_TO_RELATE_INIT';
export const SEARCH_CUSTOMER_TO_RELATE_SUCCESS = 'SEARCH_CUSTOMER_TO_RELATE_SUCCESS';
export const SEARCH_CUSTOMER_TO_RELATE_FAIL = 'SEARCH_CUSTOMER_TO_RELATE_FAIL';

export const RELATE_CUSTOMER_INIT = 'RELATE_CUSTOMER_INIT';
export const RELATE_CUSTOMER_SUCCESS = 'RELATE_CUSTOMER_SUCCESS';
export const RELATE_CUSTOMER_FAIL = 'RELATE_CUSTOMER_FAIL';

export const RELATE_CUSTOMER_AND_REDIRECT_INIT = 'RELATE_CUSTOMER_AND_REDIRECT_INIT';
export const RELATE_CUSTOMER_AND_REDIRECT_SUCCESS = 'RELATE_CUSTOMER_AND_REDIRECT_SUCCESS';
export const RELATE_CUSTOMER_AND_REDIRECT_FAIL = 'RELATE_CUSTOMER_AND_REDIRECT_FAIL';

export const FETCH_MACHINE_LIST_INIT = 'FETCH_MACHINE_LIST_INIT';
export const FETCH_MACHINE_LIST_SUCCESS = 'FETCH_MACHINE_LIST_SUCCESS';
export const FETCH_MACHINE_LIST_FAIL = 'FETCH_MACHINE_LIST_FAIL';

export const FETCH_LICENSE_NUMBERS_INIT = 'FETCH_LICENSE_NUMBERS_INIT';
export const FETCH_LICENSE_NUMBERS_SUCCESS = 'FETCH_LICENSE_NUMBERS_SUCCESS';
export const FETCH_LICENSE_NUMBERS_FAIL = 'FETCH_LICENSE_NUMBERS_FAIL';

export const FETCH_AUTOUPDATE_MACHINE_LIST_INIT = 'FETCH_AUTOUPDATE_MACHINE_LIST_INIT';
export const FETCH_AUTOUPDATE_MACHINE_LIST_SUCCESS = 'FETCH_AUTOUPDATE_MACHINE_LIST_SUCCESS';
export const FETCH_AUTOUPDATE_MACHINE_LIST_FAIL = 'FETCH_AUTOUPDATE_MACHINE_LIST_FAIL';

export const FETCH_ORDER_CODE_EXTENSION_INIT = 'FETCH_ORDER_CODE_EXTENSION_INIT';
export const FETCH_ORDER_CODE_EXTENSION_SUCCESS = 'FETCH_ORDER_CODE_EXTENSION_SUCCESS';
export const FETCH_ORDER_CODE_EXTENSION_FAIL = 'FETCH_ORDER_CODE_EXTENSION_FAIL';

export const FETCH_CONFIGURATIONS_INIT = 'FETCH_CONFIGURATIONS_INIT';
export const FETCH_CONFIGURATIONS_SUCCESS = 'FETCH_CONFIGURATIONS_SUCCESS';
export const FETCH_CONFIGURATIONS_FAIL = 'FETCH_CONFIGURATIONS_FAIL';

export const NO_ACTION = 'NO_ACTION';
export const RESET_MODAL_STATUS = 'RESET_MODAL_STATUS';
export const RESET_MESSAGE = 'RESET_MESSAGE';

// Birdhub related action-types
export const FETCH_CREDENTIAL_INIT = 'FETCH_CREDENTIAL_INIT';
export const FETCH_CREDENTIAL_SUCCESS = 'FETCH_CREDENTIAL_SUCCESS';
export const FETCH_CREDENTIAL_FAIL = 'FETCH_CREDENTIAL_FAIL';

export const ADD_CREDENTIAL_INIT = 'ADD_CREDENTIAL_INIT';
export const ADD_CREDENTIAL_SUCCESS = 'ADD_CREDENTIAL_SUCCESS';
export const ADD_CREDENTIAL_FAIL = 'ADD_CREDENTIAL_FAIL';

export const UPDATE_CREDENTIAL_INIT = 'UPDATE_CREDENTIAL_INIT';
export const UPDATE_CREDENTIAL_SUCCESS = 'UPDATE_CREDENTIAL_SUCCESS';
export const UPDATE_CREDENTIAL_FAIL = 'UPDATE_CREDENTIAL_FAIL';

// Phoenix related action-types
export const EMPTY_PHOENIX_LICENSES = 'EMPTY_PHOENIX_LICENSES';
export const FETCH_PHOENIX_LICENSE_INIT = 'FETCH_PHOENIX_LICENSE_INIT';
export const FETCH_PHOENIX_LICENSE_SUCCESS = 'FETCH_PHOENIX_LICENSE_SUCCESS';
export const FETCH_PHOENIX_LICENSE_FAIL = 'FETCH_PHOENIX_LICENSE_FAIL';

export const FETCH_PHOENIX_INCOMING_LICENSES_INIT = 'FETCH_PHOENIX_INCOMING_LICENSES_INIT';
export const FETCH_PHOENIX_INCOMING_LICENSES_SUCCESS = 'FETCH_PHOENIX_INCOMING_LICENSES_SUCCESS';
export const FETCH_PHOENIX_INCOMING_LICENSE_PARTNER_URLS = 'FETCH_PHOENIX_INCOMING_LICENSE_PARTNER_URLS';
export const FETCH_PHOENIX_INCOMING_LICENSES_FAIL = 'FETCH_PHOENIX_INCOMING_LICENSES_FAIL';


export const FETCH_COMPANIES_INIT = 'FETCH_COMPANIES_INIT';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL';

export const FETCH_COMPANY_INIT = 'FETCH_COMPANY_INIT';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

//Jumpbird related action-types
export const EMPTY_JUMPBIRD_LICENSES = 'EMPTY_JUMPBIRD_LICENSES';
export const FETCH_JUMPBIRD_LICENSE_INIT = 'FETCH_JUMPBIRD_LICENSE_INIT';
export const FETCH_JUMPBIRD_LICENSE_SUCCESS = 'FETCH_JUMPBIRD_LICENSE_SUCCESS';
export const FETCH_JUMPBIRD_LICENSE_FAIL = 'FETCH_JUMPBIRD_LICENSE_FAIL';

//Resigo related action-types
export const EMPTY_RESIGO_LICENSES = 'EMPTY_RESIGO_LICENSES';
export const FETCH_RESIGO_LICENSE_INIT = 'FETCH_RESIGO_LICENSE_INIT';
export const FETCH_RESIGO_LICENSE_SUCCESS = 'FETCH_RESIGO_LICENSE_SUCCESS';
export const FETCH_RESIGO_LICENSE_FAIL = 'FETCH_RESIGO_LICENSE_FAIL';

export const FETCH_RESIGO_MACHINE_LICENSE_INIT = 'FETCH_RESIGO_MACHINE_LICENSE_INIT';
export const FETCH_RESIGO_MACHINE_LICENSE_SUCCESS = 'FETCH_RESIGO_MACHINE_LICENSE_SUCCESS';
export const FETCH_RESIGO_MACHINE_LICENSE_FAIL = 'FETCH_RESIGO_MACHINE_LICENSE_FAIL';

export const UPDATE_RESIGO_LICENSE_INIT = 'UPDATE_RESIGO_LICENSE_INIT';
export const UPDATE_RESIGO_LICENSE_SUCCESS = 'UPDATE_RESIGO_LICENSE_SUCCESS';
export const UPDATE_RESIGO_LICENSE_FAIL = 'UPDATE_RESIGO_LICENSE_FAIL';

export const ADD_RESIGO_HARDWARE_INIT = 'ADD_RESIGO_HARDWARE_INIT';
export const ADD_RESIGO_HARDWARE_SUCCESS = 'ADD_RESIGO_HARDWARE_SUCCESS';
export const ADD_RESIGO_HARDWARE_FAIL = 'ADD_RESIGO_HARDWARE_FAIL';

export const ADD_RESIGO_LICENSE_INIT = 'ADD_RESIGO_LICENSE_INIT';
export const ADD_RESIGO_LICENSE_SUCCESS = 'ADD_RESIGO_LICENSE_SUCCESS';
export const ADD_RESIGO_LICENSE_FAIL = 'ADD_RESIGO_LICENSE_FAIL';

export const ADD_RESIGO_HARDWARE_WITH_EMAIL_INIT = 'ADD_RESIGO_HARDWARE_WITH_EMAIL_INIT';
export const ADD_RESIGO_HARDWARE_WITH_EMAIL_SUCCESS = 'ADD_RESIGO_HARDWARE_WITH_EMAIL_SUCCESS';
export const ADD_RESIGO_HARDWARE_WITH_EMAIL_FAIL = 'ADD_RESIGO_HARDWARE_WITH_EMAIL_FAIL';

export const ADD_RESIGO_LICENSE_WITH_EMAIL_INIT = 'ADD_RESIGO_LICENSE_WITH_EMAIL_INIT';
export const ADD_RESIGO_LICENSE_WITH_EMAIL_SUCCESS = 'ADD_RESIGO_LICENSE_WITH_EMAIL_SUCCESS';
export const ADD_RESIGO_LICENSE_WITH_EMAIL_FAIL = 'ADD_RESIGO_LICENSE_WITH_EMAIL_FAIL';

export const FETCH_RESIGO_ADMIN_MACHINES_INIT = 'FETCH_RESIGO_ADMIN_MACHINES_INIT';
export const FETCH_RESIGO_ADMIN_MACHINES_SUCCESS = 'FETCH_RESIGO_ADMIN_MACHINES_SUCCESS';
export const FETCH_RESIGO_ADMIN_MACHINES_FAIL = 'FETCH_RESIGO_ADMIN_MACHINES_FAIL';

export const FETCH_RESIGO_ADMIN_LICENSES_INIT = 'FETCH_RESIGO_ADMIN_LICENSES_INIT';
export const FETCH_RESIGO_ADMIN_LICENSES_SUCCESS = 'FETCH_RESIGO_ADMIN_LICENSES_SUCCESS';
export const FETCH_RESIGO_ADMIN_LICENSES_FAIL = 'FETCH_RESIGO_ADMIN_LICENSES_FAIL';

export const DELETE_RESIGO_LICENSE_INIT = 'DELETE_RESIGO_LICENSE_INIT'
export const DELETE_RESIGO_LICENSE_SUCCESS = 'DELETE_RESIGO_LICENSE_SUCCESS';
export const DELETE_RESIGO_LICENSE_FAIL = 'DELETE_RESIGO_LICENSE_FAIL';



export const FETCH_JUMPBIRD_COMPANIES_INIT = 'FETCH_JUMPBIRD_COMPANIES_INIT';
export const FETCH_JUMPBIRD_COMPANIES_SUCCESS = 'FETCH_JUMPBIRD_COMPANIES_SUCCESS';
export const FETCH_JUMPBIRD_COMPANIES_FAIL = 'FETCH_JUMPBIRD_COMPANIES_FAIL';

export const FETCH_JUMPBIRD_COMPANY_INIT = 'FETCH_JUMPBIRD_COMPANY_INIT';
export const FETCH_JUMPBIRD_COMPANY_SUCCESS = 'FETCH_JUMPBIRD_COMPANY_SUCCESS';
export const FETCH_JUMPBIRD_COMPANY_FAIL = 'FETCH_JUMPBIRD_COMPANY_FAIL';

export const FETCH_JUMPBIRD_INCOMING_LICENSES_INIT = 'FETCH_JUMPBIRD_INCOMING_LICENSES_INIT';
export const FETCH_JUMPBIRD_INCOMING_LICENSES_SUCCESS = 'FETCH_JUMPBIRD_INCOMING_LICENSES_SUCCESS';
export const FETCH_JUMPBIRD_INCOMING_LICENSE_PARTNER_URLS = 'FETCH_JUMPBIRD_INCOMING_LICENSE_PARTNER_URLS';
export const FETCH_JUMPBIRD_INCOMING_LICENSES_FAIL = 'FETCH_JUMPBIRD_INCOMING_LICENSES_FAIL';

export const FETCH_JUMPBIRD_PACKAGES_INIT = 'FETCH_JUMPBIRD_PACKAGES_INIT';
export const FETCH_JUMPBIRD_PACKAGES_SUCCESS = 'FETCH_JUMPBIRD_PACKAGES_SUCCESS';
export const FETCH_JUMPBIRD_PACKAGES_FAIL = 'FETCH_JUMPBIRD_PACKAGES_FAIL';

export const MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_INIT = 'MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_INIT';
export const MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_SUCCESS = 'MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_SUCCESS';
export const MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_FAIL = 'MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_FAIL';

export const ASSIGN_JUMPBIRD_SUBSCRIPTION_INIT = 'ASSIGN_JUMPBIRD_SUBSCRIPTION_INIT';
export const ASSIGN_JUMPBIRD_SUBSCRIPTION_SUCCESS = 'ASSIGN_JUMPBIRD_SUBSCRIPTION_SUCCESS';
export const ASSIGN_JUMPBIRD_SUBSCRIPTION_FAIL = 'ASSIGN_JUMPBIRD_SUBSCRIPTION_FAIL';

export const CHANGE_JUMPBIRD_COMPANY_STATUS_INIT = 'CHANGE_JUMPBIRD_COMPANY_STATUS_INIT'
export const CHANGE_JUMPBIRD_COMPANY_STATUS_SUCCESS = 'CHANGE_JUMPBIRD_COMPANY_STATUS_SUCCESS';
export const CHANGE_JUMPBIRD_COMPANY_STATUS_FAIL = 'CHANGE_JUMPBIRD_COMPANY_STATUS_FAIL';

export const JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_INIT = 'JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_INIT'
export const JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_SUCCESS = 'JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_SUCCESS';
export const JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_FAIL = 'JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_FAIL';

export const SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_INIT = 'SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_INIT'
export const SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_SUCCESS = 'SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_SUCCESS';
export const SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_FAIL = 'SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_FAIL';

//-----------------------------------------------------------------------------------------------------------------------------//

export const FETCH_MODULES_INIT = 'FETCH_MODULES_INIT';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_FAIL = 'FETCH_MODULES_FAIL';

export const ADD_MODULE_INIT = 'ADD_MODULE_INIT';
export const ADD_MODULE_SUCCESS = 'ADD_MODULE_SUCCESS';
export const ADD_MODULE_FAIL = 'ADD_MODULE_FAIL';

export const UPDATE_MODULE_INIT = 'UPDATE_MODULE_INIT';
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS';
export const UPDATE_MODULE_FAIL = 'UPDATE_MODULE_FAIL';

export const CHANGE_MODULE_STATUS_INIT = 'CHANGE_MODULE_STATUS_INIT';
export const CHANGE_MODULE_STATUS_SUCCESS = 'CHANGE_MODULE_STATUS_SUCCESS';
export const CHANGE_MODULE_STATUS_FAIL = 'CHANGE_MODULE_STATUS_FAIL';

export const DELETE_MODULE_INIT = 'DELETE_MODULE_INIT';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAIL = 'DELETE_MODULE_FAIL';

export const FETCH_PACKAGES_INIT = 'FETCH_PACKAGES_INIT';
export const FETCH_PACKAGES_SUCCESS = 'FETCH_PACKAGES_SUCCESS';
export const FETCH_PACKAGES_FAIL = 'FETCH_PACKAGES_FAIL';

export const FETCH_INVENTORIES_INIT = 'FETCH_INVENTORIES_INIT';
export const FETCH_INVENTORIES_SUCCESS = 'FETCH_INVENTORIES_SUCCESS';
export const FETCH_INVENTORIES_FAIL = 'FETCH_INVENTORIES_FAIL';

export const CHANGE_INVENTORY_STATUS_INIT = 'CHANGE_INVENTORY_STATUS_INIT';
export const CHANGE_INVENTORY_STATUS_SUCCESS = 'CHANGE_INVENTORY_STATUS_SUCCESS';
export const CHANGE_INVENTORY_STATUS_FAIL = 'CHANGE_INVENTORY_STATUS_FAIL';

export const UPDATE_PACKAGE_INIT = 'UPDATE_PACKAGE_INIT';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAIL = 'UPDATE_PACKAGE_FAIL';

export const CHANGE_PACKAGE_STATUS_INIT = 'UPDATE_PACKAGE_INIT';
export const CHANGE_PACKAGE_STATUS_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const CHANGE_PACKAGE_STATUS_FAIL = 'UPDATE_PACKAGE_FAIL';

export const FETCH_API_KEYS_INIT = 'FETCH_API_KEYS_INIT';
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS';
export const FETCH_API_KEYS_FAIL = 'FETCH_API_KEYS_FAIL';

// TSE device related action-types
export const FETCH_TSE_DEVICES_INIT = 'FETCH_TSE_DEVICES_INIT';
export const FETCH_TSE_DEVICES_SUCCESS = 'FETCH_TSE_DEVICES_SUCCESS';
export const FETCH_TSE_DEVICES_FAIL = 'FETCH_TSE_DEVICES_FAIL';

export const ACTIVATE_TSE_DEVICE_INIT = 'ACTIVATE_TSE_DEVICE_INIT';
export const ACTIVATE_TSE_DEVICE_SUCCESS = 'ACTIVATE_TSE_DEVICE_SUCCESS';
export const ACTIVATE_TSE_DEVICE_FAIL = 'ACTIVATE_TSE_DEVICE_FAIL';

// TSE customer related action-types
export const FETCH_TSE_CUSTOMER_INIT = 'FETCH_TSE_CUSTOMER_INIT';
export const FETCH_TSE_CUSTOMER_SUCCESS = 'FETCH_TSE_CUSTOMER_SUCCESS';
export const FETCH_TSE_CUSTOMER_FAIL = 'FETCH_TSE_CUSTOMER_FAIL';

export const FETCH_TSE_CUSTOMER_V2_INIT = 'FETCH_TSE_CUSTOMER_V2_INIT';
export const FETCH_TSE_CUSTOMER_V2_SUCCESS = 'FETCH_TSE_CUSTOMER_V2_SUCCESS';
export const FETCH_TSE_CUSTOMER_V2_FAIL = 'FETCH_TSE_CUSTOMER_V2_FAIL';

export const FETCH_TSE_BACKUP_HISTORY_INIT = 'FETCH_TSE_BACKUP_HISTORY_INIT';
export const FETCH_TSE_BACKUP_HISTORY_SUCCESS = 'FETCH_TSE_BACKUP_HISTORY_SUCCESS';
export const FETCH_TSE_BACKUP_HISTORY_FAIL = 'FETCH_TSE_BACKUP_HISTORY_FAIL';

// Tools related action-types

export const FETCH_ZM_LIST_INIT = 'FETCH_ZM_LIST_INIT';
export const FETCH_ZM_LIST_SUCCESS = 'FETCH_ZM_LIST_SUCCESS';
export const FETCH_ZM_LIST_FAIL = 'FETCH_ZM_LIST_FAIL';


// Povisioning related action-types
export const FETCH_REPORT_LIST_INIT = 'FETCH_REPORT_LIST_INIT';
export const FETCH_REPORT_LIST_SUCCESS = 'FETCH_REPORT_LIST_SUCCESS';
export const FETCH_REPORT_LIST_FAIL = 'FETCH_REPORT_LIST_FAIL';

export const UPDATE_REPORT_LIST = 'UPDATE_REPORT_LIST';

// Rea list related action-types
export const FETCH_REA_LIST_INIT = 'FETCH_REA_LIST_INIT';
export const FETCH_REA_LIST_SUCCESS = 'FETCH_REA_LIST_SUCCESS';
export const FETCH_REA_LIST_FAIL = 'FETCH_REA_LIST_FAIL';

// Turnover related action-types
export const FETCH_TURNOVER_INIT = 'FETCH_TURNOVER_INIT';
export const FETCH_TURNOVER_SUCCESS = 'FETCH_TURNOVER_SUCCESS';
export const FETCH_TURNOVER_FAIL = 'FETCH_TURNOVER_FAIL';

// Invoice-Groups related action-types
export const FETCH_INVOICE_GROUP_INIT = 'FETCH_INVOICE_GROUP_INIT';
export const FETCH_INVOICE_GROUP_SUCCESS = 'FETCH_INVOICE_GROUP_SUCCESS';
export const FETCH_INVOICE_GROUP_FAIL = 'FETCH_INVOICE_GROUP_FAIL';
export const UPDATE_INVOICE_GROUP = 'UPDATE_INVOICE_GROUP';

// Invoice-Groups related action-types
export const FETCH_INVENTORY_GROUP_INIT = 'FETCH_INVENTORY_GROUP_INIT';
export const FETCH_INVENTORY_GROUP_SUCCESS = 'FETCH_INVENTORY_GROUP_SUCCESS';

export const FETCH_INVENTORY_GROUP_FAIL = 'FETCH_INVENTORY_GROUP_FAIL';
export const UPDATE_INVENTORY_GROUP = 'UPDATE_INVENTORY_GROUP';
export const INVENTORY_GROUP_STATUS = 'INVENTORY_GROUP_STATUS'

export const CHANGE_INVENTORY_GROUP_STATUS_SUCCESS = 'CHANGE_INVENTORY_GROUP_STATUS_SUCCESS';
export const CHANGE_INVENTORY_GROUP_STATUS_INIT = 'CHANGE_INVENTORY_GROUP_STATUS_INIT';
export const CHANGE_INVENTORY_GROUP_STATUS_FAIL = 'CHANGE_INVENTORY_GROUP_STATUS_FAIL';
// License module related action-types
export const FETCH_LICENSE_MODULES_INIT = 'FETCH_LICENSE_MODULES_INIT';
export const FETCH_LICENSE_MODULES_SUCCESS = 'FETCH_LICENSE_MODULES_SUCCESS';
export const FETCH_LICENSE_MODULES_FAIL = 'FETCH_LICENSE_MODULES_FAIL';

export const ADD_LICENSE_MODULE_INIT = 'ADD_LICENSE_MODULE_INIT';
export const ADD_LICENSE_MODULE_SUCCESS = 'ADD_LICENSE_MODULE_SUCCESS';
export const ADD_LICENSE_MODULE_FAIL = 'ADD_LICENSE_MODULE_FAIL';

export const UPDATE_LICENSE_MODULE_INIT = 'UPDATE_LICENSE_MODULE_INIT';
export const UPDATE_LICENSE_MODULE_SUCCESS = 'UPDATE_LICENSE_MODULE_SUCCESS';
export const UPDATE_LICENSE_MODULE_FAIL = 'UPDATE_LICENSE_MODULE_FAIL';

export const CHANGE_LICENSE_MODULE_STATUS_INIT = 'CHANGE_LICENSE_MODULE_STATUS_INIT';
export const CHANGE_LICENSE_MODULE_STATUS_SUCCESS = 'CHANGE_LICENSE_MODULE_STATUS_SUCCESS';
export const CHANGE_LICENSE_MODULE_STATUS_FAIL = 'CHANGE_LICENSE_MODULE_STATUS_FAIL';

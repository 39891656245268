import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Spinner from 'shared/components/spinner/spinner';
import { UserProfileResponse } from 'features/login/interface/login.interface';
// import { PbTable, PbHead, PbRow, PbBody, TableEmptyContainer, TableSpinner, PbCell } from 'shared/components/table';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';

const userStatus: { [key: number]: string } = {
	0: 'inactive',
	1: 'active'
}

interface Props {
	userData: UserProfileResponse;
	loading: boolean;
	handleUpdateProfile: () => void;
}


const Profile: React.FC<Props> = (props) => {
	if (isEmpty(props.userData)) {
		return null;
	}

	// const permissions = Object.keys(props.userData.user.permissions);
	return (
		<div className='row'>
			<div className='cols-xs-12 col-sm-12 col-md-12 col-lg-12'>
				<div className='custom-card'>
					<div className='card-heading d-flex'>
						<h4><Translate text={localizationConstants.profile} /></h4>
						<div data-testid='edit-profile' className='edit-profile-btn' onClick={props.handleUpdateProfile}>
							<i className='fa fa-pencil' />
						</div>
					</div>
					{props.loading ? <Spinner /> :
						<div className='card-description-wrapper'>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.name} />:</label>
								<p>{props.userData.user.name}</p>
							</div>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.email} />:</label>
								<p>{props.userData.user.email}</p>
							</div>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.role} />:</label>
								<p>{props.userData.user.roles.join(', ')}</p>
							</div>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.status} />:</label>
								<p>{userStatus[props.userData.user.status]}</p>
							</div>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.language} />:</label>
								{props.userData.user.language === 'de' &&
									<p><Translate text={localizationConstants.german} /></p>
								}
								{props.userData.user.language === 'en' &&
									<p><Translate text={localizationConstants.english} /></p>
								}
							</div>
						</div>}
				</div>
			</div>
			{/* Commented user permission list */}
			{/* <div className='cols-xs-12 col-sm-6 col-md-6 col-lg-8'>
				<div className='ibox float-e-margins'>
					<div className='ibox-content'>
						<PbTable>
							<PbHead>
								<PbRow>
									<PbCell header>{localizationConstants.serialNumber}</PbCell>
									<PbCell header>{localizationConstants.name}</PbCell>
								</PbRow>
							</PbHead>
							<PbBody>
								{props.loading && <TableSpinner colSpan={2} />}
								{!props.loading && !permissions.length &&
									<TableEmptyContainer colSpan={2} text={localizationConstants.noPermissionsAvailable} />}
								{!props.loading && permissions.map((permission, index) => (
									<PbRow key={permission} className='gradeX'>
										<PbCell>{index + 1}</PbCell>
										<PbCell>{permission}</PbCell>
									</PbRow>
								))}
							</PbBody>
						</PbTable>
					</div>
				</div>
			</div> */}
		</div>
	)
}

export default Profile;

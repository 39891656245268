import React from 'react';
import { FormikValues } from 'formik';

import { CustomForm } from 'shared/components/form/form';
import { FieldConfig } from 'shared/components/form/inputTypes';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import * as schema from 'shared/constants/validation-schema';
import { ForgetPasswordParams } from '../interface/company';
import PhoenixLogo from 'assets/images/phoenix_logo_green.png';

interface Props {
	isLoading: boolean;
	isSuccessful: boolean;
	// message: string;
	forgetPassword: (email: ForgetPasswordParams) => void;
}

const ForgetPasswordForm: React.FC<Props> = (props) => {
	const { isLoading, isSuccessful, forgetPassword } = props;
	const onForgetPassword = (values: FormikValues) => forgetPassword({ email: values.email });
	return (
		<div className='middle-box loginscreen animated fadeInDown'>
			<div className="ibox">
				<div className="ibox-content b-r-md">
					<img
	          src={PhoenixLogo}
	          style={{ width: '200px' }}
	          alt="phoenix-logo"
	          className="m-b-sm"
	        />
					<h2 className="font-bold">
						<Translate text={localizationConstants.forgetPassword} />
					</h2>
					<div style={{ height: '12rem', display: 'grid', alignItems: 'center' }}>
					{!isSuccessful ? (
						<CustomForm
							showTitle={true}
							showLabels={false}
							formClassName='m-t'
							schema={schema.forgetPasswordValidationSchema}
							onSubmit={onForgetPassword}
							loading={isLoading}
							fieldConfig={fieldConfig}
							submitBtnText={localizationConstants.getPasswordResetCode}
							submitBtnClassName='block full-width m-b'
						/>
					) : (
						<div className='success-elem'>
							<p><Translate text={localizationConstants.resetInstructionsAreSentToYourEmail} /></p>
						</div>
					)}
				</div>
				</div>
			</div>
		</div>
	)
}

const fieldConfig: FieldConfig[] = [
	{ type: 'email', label: localizationConstants.email, name: 'email' },
]

export default ForgetPasswordForm;

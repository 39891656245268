import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormikValues } from 'formik';
import queryString from 'query-string';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';

import { ResetPasswordParams } from '../interface/company';
import ResetPasswordForm from '../component/resetPasswordForm';
import { Translate } from 'shared/components/translate';

interface UIState {
	isLoading: boolean,
	isSuccessfull: boolean;
	message: string;
}
class ResetPassword extends React.Component<RouteComponentProps<{ token: string }>> {
	state: UIState = {
		isLoading: false,
		isSuccessfull: false,
		message: ''
	}

	render() {
		const { isLoading, isSuccessfull, message } = this.state;
		return (
			<div className='gray-bg phoenix-password-wrapper forget-password-wrapper phoenix-background-svg'>
				<ResetPasswordForm
					isLoading={isLoading}
					isSuccessful={isSuccessfull}
					message={message}
					resetPassword={this.resetPassword}
				/>
			</div>
		)
	}

	resetPassword = (values: FormikValues) => {
		this.setState({ isLoading: true });
		const queryParams = queryString.parse(this.props.location.search);
		const data: ResetPasswordParams = {
			email: queryParams.email as string,
			password: values.password,
			token: this.props.match.params.token
		};
		HttpService.post(API_CONFIG.path.phoenixResetPassword, data).then((response) => {
			this.setState({ isLoading: false, isSuccessfull: true, message: <Translate text={'phoenixPasswordSuccessfullyReset'} /> });
		}).catch((err: Error) => {
			this.setState({ isLoading: false });
		});
	}
}

export default withRouter(ResetPassword);

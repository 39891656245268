import * as actionTypes from 'store/action-types';
import { Action, CustomerLicenseState } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: CustomerLicenseState = {
	license: [],
	phoenixLicense: [],
	jumpbirdLicense: [],
	resigoLicense: [],
	newLicense: [],
	licenseModules: [],
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0,
	},
	columns: {},
	sort: {
		orderBy: 'desc',
		orderByColumn: 'id'
	},
	closeModal: false,
	emailTrigger: false,
	hardwareAdded: false,
	refreshPage: false,
};

const reducer = (state: CustomerLicenseState = initialState, action: Action): CustomerLicenseState => {
	switch (action.type) {
		case actionTypes.EMPTY_LICENSES:
		case actionTypes.FETCH_LICENSE_INIT:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: 0,
				},
				license: []
			}
		case actionTypes.FETCH_LICENSE_SUCCESS:
			return {
				...state,
				license: action.payload.licenses,
				pagination: action.payload.pagination,
			}
		case actionTypes.FETCH_PHOENIX_LICENSE_INIT:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: 0,
				},
				phoenixLicense: []
			}
		case actionTypes.FETCH_PHOENIX_LICENSE_SUCCESS:
			return {
				...state,
				phoenixLicense: action.payload.licenses,
				pagination: action.payload.pagination,
			}
		case actionTypes.FETCH_JUMPBIRD_LICENSE_INIT:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: 0,
				},
				columns: {},
				jumpbirdLicense: []
			}
		case actionTypes.FETCH_JUMPBIRD_LICENSE_SUCCESS:
			return {
				...state,
				columns: action.payload.columns,
				jumpbirdLicense: action.payload.licenses,
				pagination: action.payload.pagination,
			}
		case actionTypes.FETCH_RESIGO_LICENSE_INIT:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: 0,
				},
				columns: {},
				resigoLicense: []
			}
		case actionTypes.FETCH_RESIGO_LICENSE_SUCCESS:
			return {
				...state,
				resigoLicense: action.payload.licenses,
				pagination: action.payload.pagination,
			}
		case actionTypes.CHANGE_COMPANY_LOCK_STATUS_SUCCESS:
			return {
				...state,
				phoenixLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				jumpbirdLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.ASSIGN_JUMPBIRD_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				jumpbirdLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.CHANGE_JUMPBIRD_COMPANY_STATUS_SUCCESS:
			return {
				...state,
				jumpbirdLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				jumpbirdLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_SUCCESS:
			return {
				...state,
				jumpbirdLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.MANAGE_PHOENIX_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				phoenixLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.CHANGE_MASTER_PASSWORD_SUCCESS:
		case actionTypes.MANAGE_PHOENIX_MODULES_SUCCESS:
			return {
				...state,
				closeModal: true
			}
		case actionTypes.UPDATE_LICENSE_SUCCESS:
			return {
				...state,
				license: action.payload.licenses,
				closeModal: true
			}
		case actionTypes.UPDATE_RESIGO_LICENSE_SUCCESS:
			return {
				...state,
				license: action.payload.licenses,
				closeModal: true
			}
		case actionTypes.SEND_LICENSE_EMAIL_SUCCESS:
			return {
				...state,
				closeModal: true
			}
		case actionTypes.ADD_HARDWARE_SUCCESS:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: state.pagination.total + 1
				},
				license: action.payload.license,
				refreshPage: action.payload.refreshPage,
				closeModal: true
			}
		case actionTypes.ADD_HARDWARE_WITH_EMAIL_SUCCESS:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: state.pagination.total + 1
				},
				license: action.payload.license,
				newLicense: action.payload.newLicense,
				refreshPage: action.payload.refreshPage,
				closeModal: true,
				emailTrigger: true,
				hardwareAdded: true
			}
		case actionTypes.ADD_RESIGO_HARDWARE_SUCCESS:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: state.pagination.total + 1
				},
				resigoLicense: action.payload.license,
				closeModal: true
			}
		case actionTypes.ADD_RESIGO_HARDWARE_WITH_EMAIL_SUCCESS:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: state.pagination.total + 1
				},
				resigoLicense: action.payload.license,
				newLicense: action.payload.newLicense,
				closeModal: true,
				emailTrigger: true,
				hardwareAdded: true
			}
		case actionTypes.MANAGE_MODULES_SUCCESS:
			return {
				...state,
				license: action.payload.license,
				closeModal: true
			}
		case actionTypes.ADD_RESIGO_LICENSE_SUCCESS:
		case actionTypes.ADD_CUSTOMER_LICENSE_SUCCESS:
			return {
				...state,
				newLicense: action.payload.newLicense,
				closeModal: true
			}
		case actionTypes.ADD_RESIGO_LICENSE_WITH_EMAIL_SUCCESS:
		case actionTypes.ADD_CUSTOMER_LICENSE_WITH_EMAIL_SUCCESS:
			return {
				...state,
				newLicense: action.payload.newLicense,
				closeModal: true,
				emailTrigger: true
			}
		case actionTypes.GET_CUSTOMER_LICENSE_MODULE_SUCCESS:
			return {
				...state,
				licenseModules: action.payload.modules
			}
		case actionTypes.SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_SUCCESS:
			return {
				...state,
				licenseModules: action.payload.license,
				closeModal: true
			}
		case actionTypes.GET_VALID_LICENSE_SUCCESS:
			return {
				...state,
				license: action.payload.license,
				closeModal: true
			}
		case actionTypes.ACTIVATE_FUTURE_LICENSE_SUCCESS:
			return {
				...state,
				license: action.payload.license,
				closeModal: true
			}
		case actionTypes.DELETE_LICENSE_SUCCESS:
			return {
				...state,
				license: action.payload.license,
				closeModal: true
			}
		case actionTypes.DELETE_RESIGO_LICENSE_SUCCESS:
			return {
				...state,
				resigoLicense: action.payload.license,
				closeModal: true
			}
		case 'RESET_MODAL':
			return {
				...state,
				closeModal: false,
				emailTrigger: false,
				hardwareAdded: false,
				refreshPage: false
			}
		default:
			return state;
	}
};

export default reducer;

import React, { useState } from 'react';
import { FormGroup, Modal } from 'react-bootstrap';

import CustomModal, { ModalProps } from '../modal/modal';
import Button from '../form/button';
import localizationConstants from 'shared/util/translation/constants';
import i18n from 'shared/util/localization';
import { string } from 'yup';
import PermissionManager from 'shared/hoc/permission/permissionManager';

interface customButtonsProps {
	type: "default" | "primary" | "warning" | "danger" | "info" | undefined;
	callback: React.MouseEventHandler<any>;
	text: string;
	permission?: string[];
}
interface ConfirmComponentProps {
	message: string;
	handleConfirm: (reason?: string) => void;
	loading?: boolean;
	showReasonField?: boolean;
	reasonLabel?: string;
	submitBtnText?: string;
	cancelBtnText?: string;
	customButtons?: customButtonsProps[];
}

const Confirm: React.FC<ModalProps & ConfirmComponentProps> = (props) => {
	const [reason, updateReason] = useState('');
	const [valid, setValid] = useState(true);
	const [loadingIndex, setLoadingIndex] = useState(-1);

	const renderCustomButtons = () => {
		if (!!props.customButtons && props.customButtons.length > 0) {
			return props.customButtons.map((customButtonObject: customButtonsProps, index: number) => {
				return (
					<PermissionManager permission={[]} key={index} >
						<Button /* className='width-100px' */ btnType={customButtonObject.type} loading={index === loadingIndex && props.loading} onClick={(args) => {
							setLoadingIndex(index);
							customButtonObject.callback(args);
						}}>
							{customButtonObject.text}
						</Button>
					</PermissionManager>
				)
			})
		}
	}
	return (
		<CustomModal show={props.show} handleClose={props.handleClose} className={(!!props.customButtons && props.customButtons.length) > 0 ? 'confirm-wrapper-with-custom-buttons' : 'confirm-wrapper'}>
			<div className='confirm-icon' data-testid='confirm-icon'><i className='fa fa-exclamation' /></div>
			<p className='mt-3 font-20 text-align-center'>{props.message}</p>
			<br />
			{props.showReasonField && (
				<FormGroup className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column' }}>
					{/* {!!props.reasonLabel && <label htmlFor="block-reason" style={{ fontSize: '16px', marginBottom: '1rem' }}>{props.reasonLabel}</label>} */}
					<textarea
						style={{ resize: 'none', maxWidth: '100%' }}
						id="block-reason"
						value={reason || ''}
						onChange={(e) => {
							updateReason(e.target.value)
							setValid(string().required().isValidSync(e.target.value));
						}}
						className='form-control'
						placeholder={props.reasonLabel || i18n.t(localizationConstants.reason)}
						rows={3}
					/>
					{(!valid && !reason) && <p className="error">{i18n.t(localizationConstants.requiredFieldValidation, { field: i18n.t('reason') })}</p>}
				</FormGroup>
			)}
			<Modal.Footer className='modal-footer-area text-align-center'>
				<Button className='width-120px' btnType='danger' onClick={props.handleClose}>
					{props.cancelBtnText || localizationConstants.no}
				</Button>
				<Button className='width-120px' btnType='primary' loading={loadingIndex === -1 && props.loading} onClick={() => {
					setLoadingIndex(-1);
					if (!props.showReasonField) {
						props.handleConfirm(reason);
					} else {
						setValid(string().required().isValidSync(reason));
						(valid && !!reason) && props.handleConfirm(reason);
					}
				}}>
					{props.submitBtnText || localizationConstants.yes}
				</Button>
				{renderCustomButtons()}
			</Modal.Footer>
		</CustomModal>
	)
}

export default Confirm;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps, Redirect, withRouter } from 'react-router-dom';
import NavItem from './navItem';
import { State, Action } from 'shared/interface';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/action-types';
import * as ProfileActions from 'features/login/store/login.action';
import * as CustomerLicenseActions from 'features/customer-license/store/customerLicense.action';
import { checkPermission } from 'shared/util/permission';

interface DispatchProps {
	renderSideNav: () => void;
	getProfile: () => void;
	getCustomerLicenseModules: () => void;
}
interface UIState {
	openStatus: {
		[key: string]: boolean;
	}
}
interface OwnProps extends RouteComponentProps, State, DispatchProps { }

class SideNav extends PureComponent<OwnProps> {
	state: UIState = {
		openStatus: {}
	};
	componentDidMount = () => {
		this.props.getProfile();
		if (checkPermission({ permission: ['customerLicenseModuleList'] })) {
			this.props.getCustomerLicenseModules();
		}
	}
	render() {
		if (!this.props.auth.authData.auth) {
			return <Redirect to='/login' />
		}
		return (
			<nav className='navbar-default navbar-static-side' role='navigation'>
				<a className='navbar-back-btn' onClick={this.hideSidebar}><i className="fa fa-arrow-left"/></a>
				<ul className='nav' id='side-menu'>
					<li className='nav-header cursor-pointer' onClick={this.goToProfile}>
						<h3 className='profile-element'>
							POSBILL UNIVERSE
						</h3>
						<span className='user-role-element text-muted text-xs block'>
							{this.props.auth.userData.user.name}
						</span>
						<div className='logo-element'>PU</div>
					</li>
					{!!this.props.auth && !!this.props.auth.userData &&
						this.props.auth.userData.sidebarMenu.map(menu => (
							<NavItem
								isOpen={this.state.openStatus[menu.name] || false}
								toggleIsOpen={this.toggleIsOpen}
								hideSidebar={this.hideSidebar}
								key={menu.permissionName}
								renderSideNav={this.props.renderSideNav}
								menu={menu}
							/>
						))
					}
				</ul>

			</nav>
		);
	}
	toggleIsOpen = (name: string) => {
		if (this.state.openStatus[name]) {
			this.setState({ openStatus: {} })
		} else {
			this.setState({
				openStatus: {
					[name]: true
				}
			});
		}
	}
	
	hideSidebar = () => {
		if (window.innerWidth < 768) {
			document.body && document.body.classList.remove('mini-navbar');
		}
	}
	
	goToProfile = () => {
		this.hideSidebar();
		this.props.history.push('/profile');
	};
}

const mapStateToProps = (state: State): State => {
	return state;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): DispatchProps => {
	return {
		/**
		 * renderSideNav - render side nav on side nav click, in order to change active tag of navigation-item
		 * when navigating from one route to another, if in any case, there isn't any action dispatch
		 * (in case if we have data in store, and we don't call API)
		 * then, store won't change, and sidenNav won't get rerendered
		 * we it's necessary to rerender sidenav, on route change, in order to update active route link
		 * renderSideNav is used to dispatch NO_ACTION, which will rerender this component
	 	*/
		renderSideNav: () => dispatch(createAction(actionTypes.NO_ACTION)),
		getProfile: () => dispatch(ProfileActions.getProfile()),
		getCustomerLicenseModules: () => dispatch(CustomerLicenseActions.getCustomerLicenseModules())
	};
}

export default connect<State, DispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(withRouter(SideNav));

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { API_CONFIG } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import { Loader } from 'shared/components/spinner/spinner';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import AuthService from 'shared/services/auth.service';
import { refreshLanguage } from 'shared/util/localization';
import logo from 'assets/images/logo.png';

interface OwnProps extends RouteComponentProps<{ token: string }> { }

/**
 * PhoenixOnboarding - phoenix company onboarding
 */
class PhoenixOnboarding extends Component<OwnProps> {
	state = {
		message: '',
		companyMessage: '',
		selfLogin: '',
		isLoading: false,
		isLoadingCompanyCreation: false,
		isCompanyCreated: false,
	};

	componentDidMount = () => {
		this.createCompany();
	}

	render() {
		const { selfLogin, message, companyMessage, isLoading, isLoadingCompanyCreation, isCompanyCreated } = this.state;
		return (
			<div className={`gray-bg login-wrapper validate-card-wrapper`}>
				<div className='middle-box loginscreen animated fadeInDown'>
					<div>
						<div className='text-center' data-testid='logo'>
							<img src={logo} alt='logo' className='logo-name' />
						</div>
						<h1 className='logo-name'>PosBill</h1>
						<div className='card'>
							<div className='content'>
								{isLoading && <Loader />}
								{!isLoading &&
									<>
										{isLoadingCompanyCreation &&
											<div className='label-text d-flex d-flex-dir-column justify-content-center align-items-center'>
												<Loader />
												<span className='mt-2' />
												<Translate text={localizationConstants.creatingCompany} />
											</div>
										}
										{isCompanyCreated && <div className='label-text success'><i className='fa fa-check' />&nbsp; <Translate text={companyMessage} /></div> }
									</>
								}
							</div>
							{isCompanyCreated &&
								<div className='content btn-wrapper no-margins mb-3'>
									<a className='btn btn-primary ignore-text-capitalize mt-2' href={!isEmpty(selfLogin) ? selfLogin : process.env.REACT_APP_PHOENIX_URL}>
										<Translate text={localizationConstants.startPhoenixNow} />
									</a>
								</div>
							}
							{!isLoading && <Translate text={message} />}
						</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * createCompany - phoenix company onboarding
	 */
	createCompany = () => {
		const parsed = queryString.parse(this.props.location.search);
		const branchId = !!parsed.branchId ? parsed.branchId : '8';
		const params: any = {
			branchId: branchId,
			email: parsed.email
		};
		if (!!parsed.name) {
            params.name = parsed.name;
		}
		if (!!parsed.password) {
            params.password = parsed.password;
		}
		if (!!parsed.billingPeriod) {
            params.billingPeriod = parsed.billingPeriod;
		}
		if (!!parsed.phone) {
            params.phone = parsed.phone;
		}
		if (!!parsed.expiring_date) {
            params.expiring_date = parsed.expiring_date;
		}
		if (!!parsed.accept_agb) {
            params.accept_agb = parsed.accept_agb === 'true' ? true : false;
		}
		if (!!parsed.accept_swn) {
            params.accept_swn = parsed.accept_swn === 'true' ? true : false;
		}
		if (!!parsed.partnerId) {
            params.partnerId = parsed.partnerId;
		}
		if (!!parsed.lang) {
			const lang = parsed.lang as string;
			params.lang = lang;
			AuthService.setUserLanguage(lang);
			refreshLanguage(lang);
		}
		this.setState({ isLoading: true });
		HttpService.post(API_CONFIG.path.phoenixCompanyOnboarding, params).then((response: any) => {
			this.setState({ isLoading: false });
			if (response.status === 'successful') {
				this.setState({ isCompanyCreated: true,  companyMessage: localizationConstants.companyVerificationSuccessMessage });
			} else if (!!response.company_id) {
				this.setState({ isLoadingCompanyCreation: true });
				const companyStatus = setInterval(() => {
					HttpService.get(`${API_CONFIG.path.companyCreation}/${response.company_id}`).then((res: any) => {
						if (res.status === 'successful') {
							clearInterval(companyStatus);
							this.setState({ isLoadingCompanyCreation: false, isCompanyCreated: true, selfLogin: res.selfLogin, companyMessage: localizationConstants.companyVerificationSuccessMessage });
						}
					}).catch((err: Error) => {
						this.setState({ isLoadingCompanyCreation: false, isCompanyCreated: false });
					});
				}, 3000);
			}
		}).catch((err: Error) => {
			if (err.message === '504 Timeout') {
				this.setState({ isLoading: false, message: localizationConstants.companyVerificationTimeoutMessage });
			} else {
				this.setState({ isLoading: false, message: localizationConstants.companyVerificationFailMessage });
			}
		});
	}
}

export default PhoenixOnboarding;

import { Translate } from 'shared/components/translate';
import React from 'react';
import './spinner.css';

interface LoaderProps {
	type: 'dot-elastic' |
	'dot-pulse' |
	'dot-flashing' |
	'dot-collision' |
	'dot-revolution' |
	'dot-carousel' |
	'dot-typing' |
	'dot-windmill' |
	'dot-bricks' |
	'dot-floating' |
	'dot-fire' |
	'dot-spin' |
	'dot-falling' |
	'dot-stretching' |
	'dot-gathering' |
	'dot-hourglass' |
	'dot-overtaking' |
	'dot-shuttle' |
	'dot-bouncing' |
	'dot-rolling',
	color: string
}

const Loader: React.FC<{ className?: string }> = (props) => {
	return (
		<div className={[props.className || '', 'spinner-wrapper'].join(' ')}>
			<div className='loading-icon fa fa-spin fa-spinner' />
		</div>
	);
}

const Spinner: React.FC<{}> = () => {
	return (
		<div className='spinner' data-testid='spinner'>
			<div className='lds-facebook'>
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}

const LoaderWithContent: React.FC<LoaderProps> = (props) => {
	return (<div className='loader-with-content'>
		<span><Translate text={(props.children || '').toString()} /></span>
		<div className={props.type} style={{ '--loader-color': props.color || 'white' } as React.CSSProperties} />
	</div>);
}

const DotLoader: React.FC = () => {
	return (
		<div className="loading-dots">
			<div className="loading-dots--dot" />
			<div className="loading-dots--dot" />
			<div className="loading-dots--dot" />
		</div>
	)
}

export default Spinner;
export {
	DotLoader,
	Loader,
	LoaderWithContent
}

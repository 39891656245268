import * as actionTypes from 'store/action-types';
import { UsersState, Action } from 'shared/interface';
import { PosBillUser } from '../interface/users.interface';

const initialState: UsersState = {
	users: [],
	archivedUsers: [],
	user: {} as PosBillUser,
	pagination: {
		current_page: 1,
		from: 0,
		last_page: 0,
		per_page: 0,
		to: 0,
		total: 0,
	},
	columns: {},
	sort: {
		orderBy: 'desc',
		orderByColumn: ''
	},
	userActionExecuted: false
}

const reducer = (state: UsersState = initialState, action: Action): UsersState => {
	switch (action.type) {
		case actionTypes.FETCH_USERS_INIT:
			return {
				...state,
				users: [],
				pagination: {
					...state.pagination,
					per_page: action.payload.perPage,
					current_page: action.payload.page
				},
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				}
			}
		case actionTypes.FETCH_USERS_SUCCESS:
			return {
				...state,
				users: action.payload.items,
				pagination: action.payload.pagination,
				columns: action.payload.columns,
			};
		case actionTypes.FETCH_ARCHIVED_USERS_INIT:
			return {
				...state,
				archivedUsers: [],
				pagination: {
					...state.pagination,
					per_page: action.payload.perPage,
					current_page: action.payload.page
				},
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				}
			}
		case actionTypes.FETCH_ARCHIVED_USERS_SUCCESS:
			return {
				...state,
				archivedUsers: action.payload.items,
				pagination: action.payload.pagination,
				columns: action.payload.columns,
			};
		case actionTypes.FETCH_USER_INIT:
			return {
				...state,
				user: {} as PosBillUser
			}
		case actionTypes.FETCH_USER_SUCCESS:
			return {
				...state,
				user: action.payload
			};
		case actionTypes.EDIT_USER_SUCCESS:
			return {
				...state,
				userActionExecuted: true
			}
		case actionTypes.DELETE_USER_SUCCESS:
			return {
				...state,
				userActionExecuted: true,
				pagination: {
					...state.pagination,
					total: state.pagination.total - 1
				},
				users: action.payload.users
			}
		case actionTypes.ADD_USER_SUCCESS:
			return {
				...state,
				userActionExecuted: true
			}
		case actionTypes.RESTORE_USER_SUCCESS:
			return {
				...state,
				userActionExecuted: true
			}
		case 'RESET_FLAG':
			return {
				...state,
				userActionExecuted: false
			}
		default:
			return state;
	}
}

export default reducer;

import * as actionTypes from 'store/action-types';
import { Action, OrganizationsState } from 'shared/interface';
import localizationConstants from 'shared/util/translation/constants';

const initialState: OrganizationsState = {
	organizationLabels: [],
	label: localizationConstants.all,
	orgType: '',
	sort: {
		orderByColumn: '',
		orderBy: 'desc'
	},
	pagination: {
		current_page: 1,
		from: 0,
		last_page: 0,
		per_page: 0,
		to: 0,
		total: 0,
	},
	columns: {},
	filterableColumns: [],
	searchableColumns: [],
	sortableColumns: [],
	search: '',
	columnSearch: {}
};

const reducer = (state: OrganizationsState = initialState, action: Action): OrganizationsState => {
	switch (action.type) {
		case actionTypes.FETCH_ORGANIZATIONS_INIT:
			return {
				...state,
				label: action.payload.label || localizationConstants.all,
				sort: {
					orderByColumn: action.payload.orderByColumn || '',
					orderBy: action.payload.orderBy || 'desc',
				},
				pagination: {
					...state.pagination,
					current_page: action.payload.page
				},
				orgType: action.payload.type,
				search: action.payload.search || '',
				columnSearch: action.payload.columnSearch
			}
		case actionTypes.FETCH_ORGANIZATIONS_SUCCESS:
			return {
				...state,
				orgType: action.payload.orgType,
				pagination: action.payload.pagination,
				searchableColumns: action.payload.searchableColumns,
				sortableColumns: action.payload.sortableColumns,
				filterableColumns: action.payload.filterableColumns,
				columns: {
					...state.columns,
					...action.payload.columns
				}
			};
		case actionTypes.FETCH_ORGANIZATION_LABELS_SUCCESS:
			return {
				...state,
				organizationLabels: action.payload
			};
		default:
			return state;
	}
};

export default reducer;

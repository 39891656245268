import * as actionTypes from 'store/action-types';
import { EmailTemplatesState, Action, PosBillEmailTemplate } from 'shared/interface';

const initialState: EmailTemplatesState = {
	templates: [],
	template: {} as PosBillEmailTemplate,
	templateActionExecuted: false
}

const reducer = (state: EmailTemplatesState = initialState, action: Action): EmailTemplatesState => {
	switch (action.type) {
		case actionTypes.FETCH_EMAIL_TEMPLATES_INIT:
			return {
				...state,
				templates: []
			}
		case actionTypes.FETCH_EMAIL_TEMPLATES_SUCCESS:
			return {
				...state,
				templates: action.payload
			}
		case actionTypes.FETCH_EMAIL_TEMPLATE_INIT:
			return {
				...state,
				template: {} as PosBillEmailTemplate
			}
		case actionTypes.FETCH_EMAIL_TEMPLATE_SUCCESS:
			return {
				...state,
				template: action.payload
			}
		case actionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS:
			return {
				...state,
				templateActionExecuted: true
			}
		case 'RESET_FLAG':
			return {
				...state,
				templateActionExecuted: false
			}
		default:
			return state;
	}
}

export default reducer;

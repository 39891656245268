import { ThunkDispatch } from 'redux-thunk';
import filter from 'lodash/filter';

import * as actionTypes from 'store/action-types';
import { createAction } from 'shared/util/utility';
import { Action, State } from 'shared/interface';
import { SearchLicenseObject, SearchPhoenixLicenseObject, SendEmailParameters, AddHardwareParameters, UpdateLicenseParameters, AddLicenseParameters, AddHardwareModuleParameters, License, JumpbirdSearchObject, UpdateResigoLicenseParameters, AddResigoHardwareParameters, AddResigoLicenseParameters, ResigoLicense } from '../interface/customerLicense.interface';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';

const fetchLicenses = (orgId: string, data: SearchLicenseObject, fromSupportPage: boolean) => {
	const apiEndpoint = `${API_CONFIG.path[fromSupportPage ? 'customerLicenseForSupport' : 'customerLicense']}/${orgId}/machines`;
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_LICENSE_INIT));
		httpService.get(apiEndpoint, data).then((response: any) => {
			dispatch(createAction(actionTypes.FETCH_LICENSE_SUCCESS, { licenses: response.items, pagination: response.pagination }));
		}).catch(() => {
			dispatch(createAction(actionTypes.FETCH_LICENSE_FAIL));
		});
	};
}

const fetchPhoenixLinceses = (orgId: string, data: SearchPhoenixLicenseObject) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_PHOENIX_LICENSE_INIT));
		httpService.get(`${API_CONFIG.path.phoenixLicense}/${orgId}/companies`, data).then((response: any) => {
			dispatch(createAction(actionTypes.FETCH_PHOENIX_LICENSE_SUCCESS, { licenses: response.items, pagination: response.pagination }));
		}).catch(() => {
			dispatch(createAction(actionTypes.FETCH_PHOENIX_LICENSE_FAIL));
		});
	};
}

const fetchJumpbirdLinceses = (orgId: string, data: JumpbirdSearchObject) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_JUMPBIRD_LICENSE_INIT));
		httpService.get(`${API_CONFIG.path.jumpbirdLicense}/${orgId}/companies`, data).then((response: any) => {
			dispatch(createAction(actionTypes.FETCH_JUMPBIRD_LICENSE_SUCCESS, { columns: response.columns, licenses: response.companies, pagination: response.pagination }));
		}).catch(() => {
			dispatch(createAction(actionTypes.FETCH_JUMPBIRD_LICENSE_FAIL));
		});
	};
}



const fetchMachineLicenses = (orgId: string, hw_id: number, fromSupportPage: boolean) => {
	const apiEndpoint = `${API_CONFIG.path[fromSupportPage ? 'customerLicenseForSupport' : 'customerLicense']}/${orgId}/machines/${hw_id}/licenses`
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.FETCH_MACHINE_LICENSE_INIT));
		httpService.get(apiEndpoint).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
				if (licenseObj.hw_id === hw_id) {
					licenseObj.active_license = response.active_license;
					licenseObj.licenses = response.licenses;
				}
				return licenseObj;
			})
			dispatch(createAction(actionTypes.FETCH_MACHINE_LICENSE_SUCCESS, { licenses: state.customerLicense.license }));
		}).catch(() => {
			dispatch(createAction(actionTypes.FETCH_MACHINE_LICENSE_FAIL));
		});
	};
}

const updateLicenseParameters = (orgId: string, hw_id: number, data: UpdateLicenseParameters,) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.UPDATE_LICENSE_INIT));
		httpService.put(`${API_CONFIG.path.customerLicense}/${orgId}/license/${hw_id}/update`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
				if (licenseObj.hw_id === hw_id) {
					if (licenseObj.licenses && licenseObj.licenses.length > 0) {
						licenseObj.licenses = licenseObj.licenses.map(license => {
							if (license.id === data.license_id) {
								license = response.item;
							}
							return license;
						});
					}
					if (!!response.item.active) {
						licenseObj.active_license = response.item;
					}
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.UPDATE_LICENSE_SUCCESS, { licenses: state.customerLicense.license }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.UPDATE_LICENSE_FAIL));
		});
	};
}

//    Resigo License Api

const fetchResigoLinceses = (orgId: string, data: JumpbirdSearchObject) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_RESIGO_LICENSE_INIT));
		httpService.get(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/machines`, data).then((response: any) => {
			dispatch(createAction(actionTypes.FETCH_RESIGO_LICENSE_SUCCESS, { licenses: response.items, pagination: response.pagination }));
		}).catch(() => {
			dispatch(createAction(actionTypes.FETCH_RESIGO_LICENSE_FAIL));
		});
	};
}

const fetchResigoMachineLicenses = (orgId: string, id: number) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.FETCH_RESIGO_MACHINE_LICENSE_INIT));
		httpService.get(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/machines/${id}/licenses`).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.resigoLicense = state.customerLicense.resigoLicense.map(licenseObj => {
				if (licenseObj.id === id) {
					licenseObj.active_license = response.active_license;
					licenseObj.licenses = response.licenses;
				}
				return licenseObj;
			})
			dispatch(createAction(actionTypes.FETCH_RESIGO_MACHINE_LICENSE_SUCCESS, { licenses: state.customerLicense.resigoLicense }));
		}).catch(() => {
			dispatch(createAction(actionTypes.FETCH_RESIGO_MACHINE_LICENSE_FAIL));
		});
	};
}

const updateResigoLicenseParameters = (id: number, private_key: string, orgId: string, data: UpdateResigoLicenseParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.UPDATE_RESIGO_LICENSE_INIT));
		httpService.put(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/machines/${id}/change-license-lock-status`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.resigoLicense = state.customerLicense.resigoLicense.map(licenseObj => {
				if (licenseObj.private_key.toString() === private_key) {
					if (licenseObj.licenses && licenseObj.licenses.length > 0) {
						licenseObj.licenses = licenseObj.licenses.map(license => {
							if (license.license_number === data.license_number) {
								license = response;
							}
							return license;
						});
					}
					if (!!response.is_active) {
						licenseObj.active_license = response;
					}
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.UPDATE_RESIGO_LICENSE_SUCCESS, { licenses: state.customerLicense.resigoLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.UPDATE_RESIGO_LICENSE_FAIL));
		});
	};
}

const updateResigoMachineParameters = (orgId: string, data: UpdateResigoLicenseParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.UPDATE_RESIGO_LICENSE_INIT));
		httpService.put(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/change-machine-lock-status`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.resigoLicense = state.customerLicense.resigoLicense.map(licenseObj => {
				if (licenseObj.private_key.toString() === (data.private_key || "").toString()) {
					const licenses = !!licenseObj.licenses ? [...licenseObj.licenses] : [];
					licenseObj = response;
					licenseObj['licenses'] = licenses;
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.UPDATE_RESIGO_LICENSE_SUCCESS, { licenses: state.customerLicense.license }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.UPDATE_RESIGO_LICENSE_FAIL));
		});
	};
}

const addResigoHardware = (orgId: string, data: AddResigoHardwareParameters, withEmail: boolean) => {
	const actionTypeInit = withEmail ? actionTypes.ADD_RESIGO_HARDWARE_WITH_EMAIL_INIT : actionTypes.ADD_RESIGO_HARDWARE_INIT;
	const actionTypeSuccess = withEmail ? actionTypes.ADD_RESIGO_HARDWARE_WITH_EMAIL_SUCCESS : actionTypes.ADD_RESIGO_HARDWARE_SUCCESS;
	const actionTypeFail = withEmail ? actionTypes.ADD_RESIGO_HARDWARE_WITH_EMAIL_FAIL : actionTypes.ADD_RESIGO_HARDWARE_FAIL;
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypeInit));
		httpService.post(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/machines`, data).then((res: any) => {
			const state = getState() as State;
			state.customerLicense.resigoLicense.unshift(res);
			httpService.get(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/machines/${res.id}/licenses`).then((response: any) => {
				dispatch(createAction(actionTypeSuccess, { license: state.customerLicense.resigoLicense, newLicense: response.licenses }));
				dispatch(createAction('RESET_MODAL'));
			}).catch(() => {
				dispatch(createAction(actionTypeFail));
			});
		}).catch(() => {
			dispatch(createAction(actionTypeFail));
		});
	};
}

const addResigoCustomerLicense = (orgId: string, id: number, data: AddResigoLicenseParameters, withEmail: boolean) => {
	const actionTypeInit = withEmail ? actionTypes.ADD_RESIGO_LICENSE_WITH_EMAIL_INIT : actionTypes.ADD_RESIGO_LICENSE_INIT;
	const actionTypeSuccess = withEmail ? actionTypes.ADD_RESIGO_LICENSE_WITH_EMAIL_SUCCESS : actionTypes.ADD_RESIGO_LICENSE_SUCCESS;
	const actionTypeFail = withEmail ? actionTypes.ADD_RESIGO_LICENSE_WITH_EMAIL_FAIL : actionTypes.ADD_RESIGO_LICENSE_FAIL;
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypeInit));
		httpService.post(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/machines/${id}/add-license`, data).then((response: any) => {
			const state = getState() as State;
			// Set newly added lincese in application state
			state.customerLicense.resigoLicense = state.customerLicense.resigoLicense.map(licenseObj => {
				if (licenseObj.id === id) {
					if (licenseObj.licenses && licenseObj.licenses.length > 0) {
						if (!!licenseObj['licenses'][0].is_active && !!response.licenses[response.licenses.length - 1].is_active) {
							licenseObj['licenses'][0].is_active = false;
						}
						licenseObj['licenses'] = [...response.licenses, ...licenseObj.licenses];
					} else {
						licenseObj['licenses'] = response.licenses;
					}
					response.licenses.map((item: any) => {
						if (item.is_active) {
							licenseObj['active_license'] = item;
						}
						return item;
					});
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypeSuccess, { newLicense: response.licenses }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypeFail));
		});
	};
}

const deleteResigoLicense = (orgId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.DELETE_RESIGO_LICENSE_INIT));
		httpService.deleteRequest(`${API_CONFIG.path.resigoLicenseCustomer}/${orgId}/machines/${data.hardware_id}/license/${data.license_id}/delete`)
			.then(() => {
				const state = getState() as State;
				state.customerLicense.resigoLicense = state.customerLicense.resigoLicense.map(licenseObj => {
					if (licenseObj.id === data.hardware_id) {
						let index = 0;
						let isActiveLicense = false;
						for (let i = 0; i < licenseObj.licenses.length; i++) {
							if (data.license_id === licenseObj.licenses[i].id) {
								if (licenseObj.licenses[i].is_active) {
									isActiveLicense = true;
								}
								index = i;
								break;
							}
						}
						licenseObj.licenses.splice(index, 1);
						if (isActiveLicense) {
							licenseObj.active_license = {} as ResigoLicense;
						}
					}
					return licenseObj;
				});
				dispatch(createAction(actionTypes.DELETE_RESIGO_LICENSE_SUCCESS, { license: state.customerLicense.resigoLicense }));
				dispatch(createAction('RESET_MODAL'));
			}).catch(() => {
				dispatch(createAction(actionTypes.DELETE_RESIGO_LICENSE_FAIL));
			});
	};
}

// ------------------------------------------------------------------------------

const updateMachineParameters = (orgId: string, hw_id: number, data: UpdateLicenseParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.UPDATE_LICENSE_INIT));
		httpService.put(`${API_CONFIG.path.customerLicense}/${orgId}/machines/${hw_id}/update`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
				if (licenseObj.hw_id === hw_id) {
					const licenses = !!licenseObj.licenses ? [...licenseObj.licenses] : [];
					licenseObj = response.item;
					licenseObj['licenses'] = licenses;
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.UPDATE_LICENSE_SUCCESS, { licenses: state.customerLicense.license }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.UPDATE_LICENSE_FAIL));
		});
	};
}

const sendEmail = (hw_id: number, orgId: string, data: SendEmailParameters, resigo: boolean) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.SEND_LICENSE_EMAIL_INIT));
		httpService.post(`${resigo ? API_CONFIG.path.resigoLicenseCustomer : API_CONFIG.path.customerLicense}/${orgId}/${resigo ? 'machines' : 'license'}/${hw_id}/send-email`, data).then(() => {
			dispatch(createAction(actionTypes.SEND_LICENSE_EMAIL_SUCCESS));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.SEND_LICENSE_EMAIL_FAIL));
		});
	};
}

const addHardware = (orgId: string, data: AddHardwareParameters, withEmail: boolean) => {
	const actionTypeInit = withEmail ? actionTypes.ADD_HARDWARE_WITH_EMAIL_INIT : actionTypes.ADD_HARDWARE_INIT;
	const actionTypeSuccess = withEmail ? actionTypes.ADD_HARDWARE_WITH_EMAIL_SUCCESS : actionTypes.ADD_HARDWARE_SUCCESS;
	const actionTypeFail = withEmail ? actionTypes.ADD_HARDWARE_WITH_EMAIL_FAIL : actionTypes.ADD_HARDWARE_FAIL;
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypeInit));
		httpService.post(`${API_CONFIG.path.customerLicense}/${orgId}/machines`, data).then((res: any) => {
			let refreshPage = false;
			if (data.hardware_key.toString().slice(-1) === '7') {
				refreshPage = true;
			}
			const state = getState() as State;
			state.customerLicense.license.unshift(res.item);
			httpService.get(`${API_CONFIG.path.customerLicense}/${orgId}/machines/${res.item.hw_id}/licenses`).then((response: any) => {
				dispatch(createAction(actionTypeSuccess, { license: state.customerLicense.license, newLicense: response.licenses, refreshPage }));
				dispatch(createAction('RESET_MODAL'));
			}).catch(() => {
				dispatch(createAction(actionTypeFail));
			});
		}).catch(() => {
			dispatch(createAction(actionTypeFail));
		});
	};
}

const addCustomerLicense = (orgId: string, hw_id: number, data: AddLicenseParameters, withEmail: boolean) => {
	const actionTypeInit = withEmail ? actionTypes.ADD_CUSTOMER_LICENSE_WITH_EMAIL_INIT : actionTypes.ADD_CUSTOMER_LICENSE_INIT;
	const actionTypeSuccess = withEmail ? actionTypes.ADD_CUSTOMER_LICENSE_WITH_EMAIL_SUCCESS : actionTypes.ADD_CUSTOMER_LICENSE_SUCCESS;
	const actionTypeFail = withEmail ? actionTypes.ADD_CUSTOMER_LICENSE_WITH_EMAIL_FAIL : actionTypes.ADD_CUSTOMER_LICENSE_FAIL;
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypeInit));
		httpService.post(`${API_CONFIG.path.customerLicense}/${orgId}/license/${hw_id}/store`, data).then((response: any) => {
			const state = getState() as State;
			// Set newly added lincese in application state
			state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
				if (licenseObj.hw_id === hw_id) {
					if (licenseObj.licenses && licenseObj.licenses.length > 0) {
						if (!!licenseObj['licenses'][0].active && !!response.items[response.items.length - 1].active) {
							licenseObj['licenses'][0].active = 0;
						}
						licenseObj['licenses'] = [...response.items, ...licenseObj.licenses];
					} else {
						licenseObj['licenses'] = response.items;
					}
					response.items.map((item: any) => {
						if (item.active) {
							licenseObj['active_license'] = item;
						}
						return item;
					});
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypeSuccess, { newLicense: response.items }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypeFail));
		});
	};
}

const getCustomerLicenseModules = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.GET_CUSTOMER_LICENSE_MODULE_INIT));
		httpService.get(API_CONFIG.path.customerLicenseModules).then((response: any) => {
			let modules: any = filter(response.items, item => item.status === 1);
			dispatch(createAction(actionTypes.GET_CUSTOMER_LICENSE_MODULE_SUCCESS, { modules }));
		}).catch(() => {
			dispatch(createAction(actionTypes.GET_CUSTOMER_LICENSE_MODULE_FAIL));
		});
	};
}

const manageModules = (orgId: string, hw_id: number, modules: AddHardwareModuleParameters[]) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.MANAGE_MODULES_INIT));
		httpService.put(`${API_CONFIG.path.customerLicense}/${orgId}/module/${hw_id}/update`, { modules }).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
				if (licenseObj.hw_id === hw_id) {
					licenseObj.modules = response.items;
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.MANAGE_MODULES_SUCCESS, { license: state.customerLicense.license }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.MANAGE_MODULES_FAIL));
		});
	};
}

const changeCompanyLockStatus = (orgId: string, universeId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.CHANGE_COMPANY_LOCK_STATUS_INIT));
		httpService.put(`${API_CONFIG.path.phoenixLicense}/${orgId}/companies/${universeId}`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.phoenixLicense = state.customerLicense.phoenixLicense.map(licenseObj => {
				if (licenseObj.universe_id === universeId) {
					licenseObj.is_locked = data.is_locked;
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.CHANGE_COMPANY_LOCK_STATUS_SUCCESS, { license: state.customerLicense.phoenixLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.CHANGE_COMPANY_LOCK_STATUS_FAIL));
		});
	};
}

const changeJumpbirdCompanyStatus = (orgId: string, companyId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.CHANGE_JUMPBIRD_COMPANY_STATUS_INIT));
		httpService.put(`${API_CONFIG.path.jumpbirdLicense}/${orgId}/companies/${companyId}/block-status`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.jumpbirdLicense = state.customerLicense.jumpbirdLicense.map(licenseObj => {
				if (licenseObj.id === companyId) {
					licenseObj.status = parseInt(data.is_blocked) === 1 ? 'block' : 'active';
					// licenseObj.sepa_request.transaction_status = (data.sepa_accepted === 1 ? 'failed' : 'paid')
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.CHANGE_JUMPBIRD_COMPANY_STATUS_SUCCESS, { license: state.customerLicense.jumpbirdLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.CHANGE_JUMPBIRD_COMPANY_STATUS_FAIL));
		});
	};
}

const cancelSubscription = (orgId: string, companyId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_INIT));
		httpService.post(`${API_CONFIG.path.jumpbirdLicense}/${orgId}/companies/${companyId}/cancel-subscription`, data).then((license: any) => {
			const state = getState() as State;
			state.customerLicense.jumpbirdLicense = state.customerLicense.jumpbirdLicense.map(licenseObj => {
				if (licenseObj.id === companyId) {
					licenseObj = license;
					// licenseObj.sepa_request.transaction_status = (data.sepa_accepted === 1 ? 'failed' : 'paid')
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_SUCCESS, { license: state.customerLicense.jumpbirdLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.JUMPBIRD_COMPANY_CANCEL_SUBSCRIPTION_FAIL));
		});
	};
}

const setPhoenixLicenseDate = (orgId: string, universeId: string, data: UpdateLicenseParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_INIT));
		httpService.put(`${API_CONFIG.path.phoenixLicense}/${orgId}/companies/${universeId}/expiry-date`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.phoenixLicense = state.customerLicense.phoenixLicense.map(licenseObj => {
				if (licenseObj.universe_id === universeId) {
					licenseObj.expiring_date = response.expiring_date;
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_SUCCESS, { license: state.customerLicense.phoenixLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_FAIL));
		});
	};
}

const setJumpbirdLicenseDate = (orgId: string, universeId: string, data: UpdateLicenseParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_INIT));
		httpService.put(`${API_CONFIG.path.jumpbirdLicense}/${orgId}/companies/${universeId}/end-date`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.jumpbirdLicense = state.customerLicense.jumpbirdLicense.map(licenseObj => {
				if (licenseObj.id.toString() === universeId.toString()) {
					licenseObj.package = { ...licenseObj.package, end_date: response.end_date };
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_SUCCESS, { license: state.customerLicense.jumpbirdLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.SET_JUMPBIRD_LICENSE_DATE_TO_UNLIMITED_FAIL));
		});
	};
}

const getValidLicense = (orgId: string, hw_id: number, forSupportPage: boolean) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.GET_VALID_LICENSE_INIT));
		httpService.post(API_CONFIG.path.customersMachineActiveLicense, { hardware_id: hw_id }).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
				if (licenseObj.hw_id === hw_id) {
					licenseObj.active_license = response.activeLicense;
					licenseObj.next_license = response.nextLicense;
				}
				return licenseObj;
			});
			fetchMachineLicenses(orgId, hw_id, forSupportPage)(dispatch, getState);
			dispatch(createAction(actionTypes.GET_VALID_LICENSE_SUCCESS, { license: state.customerLicense.license }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.GET_VALID_LICENSE_FAIL));
		});
	};
}

const activateFutureLicense = (orgId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.ACTIVATE_FUTURE_LICENSE_INIT));
		httpService.post(`${API_CONFIG.path.customerLicense}/${orgId}/license/${data.hardware_id}/activate`, { license_id: data.license_id })
			.then((response: any) => {
				const state = getState() as State;
				state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
					if (licenseObj.hw_id === data.hardware_id) {
						licenseObj.licenses = licenseObj.licenses.map(license => {
							if (license.active) {
								license.active = 0;
							}
							if (license.id === data.license_id) {
								license.active = 1;
							}
							return license;
						});
						licenseObj.active_license = response.item;
					}
					return licenseObj;
				});
				dispatch(createAction(actionTypes.ACTIVATE_FUTURE_LICENSE_SUCCESS, { license: state.customerLicense.license }));
				dispatch(createAction('RESET_MODAL'));
			}).catch(() => {
				dispatch(createAction(actionTypes.ACTIVATE_FUTURE_LICENSE_FAIL));
			});
	};
}

const deleteLicense = (orgId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.DELETE_LICENSE_INIT));
		httpService.deleteRequest(`${API_CONFIG.path.customerLicense}/${orgId}/machines/${data.hardware_id}/licenses/${data.license_id}/delete`)
			.then(() => {
				const state = getState() as State;
				state.customerLicense.license = state.customerLicense.license.map(licenseObj => {
					if (licenseObj.hw_id === data.hardware_id) {
						let index = 0;
						let isActiveLicense = false;
						for (let i = 0; i < licenseObj.licenses.length; i++) {
							if (data.license_id === licenseObj.licenses[i].id) {
								if (licenseObj.licenses[i].active === 1) {
									isActiveLicense = true;
								}
								index = i;
								break;
							}
						}
						licenseObj.licenses.splice(index, 1);
						if (isActiveLicense) {
							licenseObj.active_license = {} as License;
						}
					}
					return licenseObj;
				});
				dispatch(createAction(actionTypes.DELETE_LICENSE_SUCCESS, { license: state.customerLicense.license }));
				dispatch(createAction('RESET_MODAL'));
			}).catch(() => {
				dispatch(createAction(actionTypes.DELETE_LICENSE_FAIL));
			});
	};
}

const changeMasterPassword = (orgId: string, universeId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.CHANGE_MASTER_PASSWORD_INIT));
		httpService.post(`${API_CONFIG.path.phoenixLicense}/${orgId}/companies/${universeId}/change-password`, data).then((response: any) => {
			dispatch(createAction(actionTypes.CHANGE_MASTER_PASSWORD_SUCCESS));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.CHANGE_MASTER_PASSWORD_FAIL));
		});
	};
}

const managePhoenixSubscription = (orgId: string, universeId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.MANAGE_PHOENIX_SUBSCRIPTION_INIT));
		httpService.post(`${API_CONFIG.path.phoenixLicense}/${orgId}/companies/${universeId}/subscriptions`, data).then((response: any) => {
			const state = getState() as State;
			if (response && response.id) {
				state.customerLicense.phoenixLicense = state.customerLicense.phoenixLicense.map(licenseObj => {
					if (licenseObj.universe_id === universeId) {
						licenseObj.subscription = response;
						licenseObj.expiring_date = '1970-01-01';
					}
					return licenseObj;
				});
			}
			dispatch(createAction(actionTypes.MANAGE_PHOENIX_SUBSCRIPTION_SUCCESS, { license: state.customerLicense.phoenixLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.MANAGE_PHOENIX_SUBSCRIPTION_FAIL));
		});
	};
}

const manageJumpbirdSEPASubscription = (orgId: string, companyId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_INIT));
		httpService.put(`${API_CONFIG.path.jumpbirdLicense}/${orgId}/companies/${companyId}/manage-sepa`, data).then((license: any) => {
			const state = getState() as State;
			state.customerLicense.jumpbirdLicense = state.customerLicense.jumpbirdLicense.map(licenseObj => {
				if (licenseObj.id.toString() === companyId) {
					licenseObj = license
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_SUCCESS, { license: state.customerLicense.jumpbirdLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.MANAGE_JUMPBIRD_SEPA_SUBSCRIPTION_FAIL));
		});
	};
}

const assignJumpbirdSubscription = (orgId: string, companyId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => State | {}) => {
		dispatch(createAction(actionTypes.ASSIGN_JUMPBIRD_SUBSCRIPTION_INIT));
		httpService.post(`${API_CONFIG.path.jumpbirdLicense}/${orgId}/companies/${companyId}/assign-package`, data).then((response: any) => {
			const state = getState() as State;
			state.customerLicense.jumpbirdLicense = state.customerLicense.jumpbirdLicense.map(licenseObj => {
				if (licenseObj.id.toString() === companyId.toString()) {
					licenseObj = { ...licenseObj, package: response.company.package }
				}
				return licenseObj;
			});
			dispatch(createAction(actionTypes.ASSIGN_JUMPBIRD_SUBSCRIPTION_SUCCESS, { license: state.customerLicense.jumpbirdLicense }));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.ASSIGN_JUMPBIRD_SUBSCRIPTION_FAIL));
		});
	};
}

const managePhoenixModules = (orgId: string, universeId: string, id: number, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.MANAGE_PHOENIX_MODULES_INIT));
		httpService.put(`${API_CONFIG.path.phoenixLicense}/${orgId}/companies/${universeId}/subscriptions/${id}/modules`, data).then(() => {
			dispatch(createAction(actionTypes.MANAGE_PHOENIX_MODULES_SUCCESS));
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.MANAGE_PHOENIX_MODULES_FAIL));
		});
	};
}


export {
	fetchLicenses,
	fetchPhoenixLinceses,
	fetchJumpbirdLinceses,
	fetchResigoLinceses,
	fetchMachineLicenses,
	fetchResigoMachineLicenses,
	addResigoCustomerLicense,
	updateLicenseParameters,
	updateResigoLicenseParameters,
	updateMachineParameters,
	updateResigoMachineParameters,
	sendEmail,
	addHardware,
	addResigoHardware,
	addCustomerLicense,
	getCustomerLicenseModules,
	manageModules,
	changeCompanyLockStatus,
	deleteResigoLicense,
	changeJumpbirdCompanyStatus,
	cancelSubscription,
	changeMasterPassword,
	managePhoenixSubscription,
	manageJumpbirdSEPASubscription,
	assignJumpbirdSubscription,
	managePhoenixModules,
	setPhoenixLicenseDate,
	setJumpbirdLicenseDate,
	getValidLicense,
	activateFutureLicense,
	deleteLicense
}

import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormikValues } from 'formik';

import AuthService from 'shared/services/auth.service';
import { CustomForm } from 'shared/components/form/form';
import { FieldConfig } from 'shared/components/form/inputTypes';

import { DispatchProps, MapStateProps } from '../interface/login.interface';
import LoginWrapper from 'shared/hoc/auth/loginWrapper';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import * as schema from 'shared/constants/validation-schema';

interface Props extends DispatchProps, MapStateProps { }

const LoginComponent: React.FC<Props> = (props) => {
	const isLoggedIn = AuthService.checkLogin();
	if (isLoggedIn) {
		return (<Redirect to='/' />)
	}
	const onLogin = (values: FormikValues) => props.onLogin({ email: values.email, password: values.password });

	return (
		<LoginWrapper>
			<CustomForm
				showLabels
				title={localizationConstants.login}
				formClassName='m-t'
				schema={schema.loginFormValidationSchema}
				onSubmit={onLogin}
				loading={props.loading}
				fieldConfig={fieldConfig}
				submitBtnText={localizationConstants.login}
				submitBtnClassName='block full-width m-b'
			/>
			<div className='text-center'>
				<Link to='/forget-password'>
					<small><Translate text={localizationConstants.forgetPassword} />?</small>
				</Link>
			</div>
		</LoginWrapper>
	)
}

const fieldConfig: FieldConfig[] = [
	{ type: 'email', label: localizationConstants.email, name: 'email' },
	{ type: 'password', label: localizationConstants.password, name: 'password' },
];

export default LoginComponent;

import * as actionTypes from 'store/action-types';
import { PermissionsState, Action } from 'shared/interface';

const initialState: PermissionsState = {
	permissions: [],
	permissionsByModule: []
}

const reducer = (state: PermissionsState = initialState, action: Action): PermissionsState => {
	switch (action.type) {
		case actionTypes.FETCH_PERMISSIONS_INIT:
			return {
				...state,
				permissions: []
			}
		case actionTypes.FETCH_PERMISSIONS_SUCCESS:
			return {
				...state,
				permissions: action.payload
			};
		case actionTypes.FETCH_PERMISSIONS_BY_MODULE_SUCCESS:
			return {
				...state,
				permissionsByModule: action.payload
			};
		default:
			return state;
	}
}

export default reducer;

import * as actionTypes from 'store/action-types';
import { LicenseModuleState, Action } from 'shared/interface';

const initialState: LicenseModuleState = {
	modules: [],
	closeModal: false
}

const reducer = (state: LicenseModuleState = initialState, action: Action): LicenseModuleState => {
	switch (action.type) {
		case actionTypes.FETCH_LICENSE_MODULES_INIT:
			return {
				...state,
				modules: []
			}
		case actionTypes.FETCH_LICENSE_MODULES_SUCCESS:
			return {
				...state,
				modules: action.payload
			};
		case actionTypes.ADD_LICENSE_MODULE_SUCCESS:
			return {
				...state,
				modules: action.payload.modules,
				closeModal: true
			}
		case actionTypes.UPDATE_LICENSE_MODULE_SUCCESS:
			return {
				...state,
				modules: action.payload.modules,
				closeModal: true
			}
		case actionTypes.CHANGE_LICENSE_MODULE_STATUS_SUCCESS:
			return {
				...state,
				modules: action.payload.modules,
				closeModal: true
			}
		case 'RESET_MODAL_STATUS':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;

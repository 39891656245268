import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table/react-table.css';
import 'assets/styles/datatables.min.css'

import './assets/styles/index.scss';
import Layout from 'shared/hoc/layout/component/layout.component'
import AuthService from 'shared/services/auth.service';
import Spinner from 'shared/components/spinner/spinner';
import RouteManager from 'shared/hoc/permission/routeManager';

// import containers, for which lazy loading is not needed, as they will be rendered for all users
import NotFound from 'shared/components/404/404';
import Profile from 'features/profile/container/profile';
import Login from 'features/login/container/login.container';
import ForgetPassword from 'features/password/container/forgetPassword';
import ResetPassword from 'features/password/container/resetPassword';
import PhoenixForgetPassword from 'features/phoenix/company/container/forgetPassword';
import PhoenixResetPassword from 'features/phoenix/company/container/resetPassword';
import CompanyVerification from 'features/companyVerification/component/validate';
import PhoenixOnboarding from 'features/phoenixOnboarding/component/phoenixOnboarding';

/*
* import async components in order to lazy load the containers, which will be rendered conditionally
* based on the permissions user is having
*/
import * as asyncComponents from 'shared/hoc/asyncComponents';

const App: React.FC = () => {
	const isLoggedIn = AuthService.checkLogin();
	const isPublicPage = window.location.pathname.includes('company/validate/');
	const isPhoenixOnboarding = window.location.pathname.includes('phoenix/onboarding');
	const isPhoenixCompanyPasswordForget = window.location.pathname.includes('phoenix/company/forget-password');
	const isPhoenixCompanyPasswordReset = window.location.pathname.includes('phoenix/company/reset-password/');

	if (isPublicPage || isPhoenixOnboarding || isPhoenixCompanyPasswordForget || isPhoenixCompanyPasswordReset) {
		return (
			<Switch>
				<Route exact path='/company/validate/:token' component={CompanyVerification} />
				<Route exact path='/phoenix/onboarding' component={PhoenixOnboarding} />
				<Route exact path='/phoenix/company/forget-password' component={PhoenixForgetPassword} />
				<Route exact path='/phoenix/company/reset-password/:token' component={PhoenixResetPassword} />
				<Redirect to='/404' />
			</Switch>
		);
	} else if (isLoggedIn) {
		return (
			<Layout>
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route
							exact path='/'
							component={asyncComponents.Dashboard} />

						<RouteManager
							permission={['usersModule']} exact path='/users/list'
							component={asyncComponents.Users} />
						<RouteManager
							permission={['usersCreate']} exact path='/users/add'
							component={asyncComponents.AddUser} />
						<RouteManager
							permission={['usersUpdate']} path='/users/edit/:id'
							component={asyncComponents.EditUser} />


						<RouteManager
							permission={['organizationsSuperAdminModule']} exact path='/organizations/superAdmin/list'
							component={asyncComponents.SuperAdminOrganization} />
						<RouteManager
							permission={['organizationsSupportModule']} exact path='/organizations/support/list'
							component={asyncComponents.SupportOrganization} />
						<RouteManager
							permission={['organizationsLicenseModule']} exact path='/organizations/license/list'
							component={asyncComponents.LicenseOrganization} />
						<RouteManager
							permission={['organizationsIncomingLicenseModule']} exact path='/organizations/incoming-license'
							component={asyncComponents.OrganizationIncomingLicenses} />
						<RouteManager
							permission={['organizationsIncomingLicenseRead']} exact path='/organizations/incoming-license/:hardwareId'
							component={asyncComponents.OrganizationIncomingLicenseDetail} />
						<RouteManager
							permission={['customerLicenseModuleList']} exact path='/licenses/module/list'
							component={asyncComponents.LicenseModuleList} />

						<RouteManager
							permission={['phoenixIncomingLicenseModule']} exact path='/organizations/phoenix/incoming-license'
							component={asyncComponents.PhoenixIncomingLicenses} />

						<RouteManager
							permission={['phoenixIncomingLicenseRead']} exact path='/organizations/phoenix/incoming-license/:id'
							component={asyncComponents.CompanyDetail} />

						<RouteManager
							permission={['jumpbirdIncomingLicenseModule']} exact path='/organizations/jumpbird/incoming-license'
							component={asyncComponents.JumpbirdIncomingLicenses} />

						<RouteManager
							permission={['jumpbirdCompaniesViewDetail']} exact path='/organizations/jumpbird/incoming-license/:id'
							component={asyncComponents.JumpbirdCompanyDetail} />


						<RouteManager
							permission={['organizationsSuperAdminRead']} exact path='/organizations/superAdmin/:id'
							component={asyncComponents.SuperAdminOrgDetail} />
						<RouteManager
							permission={['organizationsSupportRead']} exact path='/organizations/support/:custId'
							component={asyncComponents.CustomerLicence} />

						{/* <RouteManager
							permission={['organizationsSupportRead']} exact path='/organizations/support/:id'
							component={asyncComponents.SupportAdminOrgDetail} /> */}
						<RouteManager
							permission={['organizationsLicenseRead']} exact path='/organizations/license/:id'
							component={asyncComponents.LicenseAdminOrgDetail} />


						<RouteManager
							anyOnePermission={['customerLicenseMachineModule', 'customerPhoenixLicenseCompanyList']} exact path='/organizations/license/list/:custId'
							component={asyncComponents.CustomerLicence} />
						<RouteManager
							anyOnePermission={['customerLicenseMachineModule', 'customerPhoenixLicenseCompanyList']} exact path='/organizations/list/:custId'
							component={asyncComponents.CustomerLicence} />

						<RouteManager
							permission={['organizationPaymentStatusDetail', 'organizationPaymentStatusDynamicFormFieldGenerate', 'organizationPaymentStatusFormFieldUpdate']} exact path='/organizations/payment-status/:custId'
							component={asyncComponents.CustomerLicence} />

						<RouteManager
							permission={['licensesIncoming']} exact path='/licenses/incoming'
							component={asyncComponents.IncomingLicenses} />
						<RouteManager
							permission={['licensesMachineList']} exact path='/licenses/machine/list'
							component={asyncComponents.MachineList} />
						<RouteManager
							permission={['autoUpdateMachineList']} exact path='/licenses/auto-update-machine/list'
							component={asyncComponents.AutoUpdateMachineList} />
						<RouteManager
							permission={['organizationsIncomingLicenseRead']} exact path='/licenses/machine/:hardwareId'
							component={asyncComponents.OrganizationIncomingLicenseDetail} />
						<RouteManager
							permission={['incomingLicenseMachineDataUpdate']} exact path='/licenses/machine/edit/:hardwareId'
							component={asyncComponents.OrganizationIncomingLicenseDetail} />
						<RouteManager
							permission={['licensesLicenseNumbers']} exact path='/licenses/license-numbers'
							component={asyncComponents.LicenseNumbers} />
						<RouteManager
							permission={['orderCodeExtensionsList']} exact path='/licenses/order-code-extension'
							component={asyncComponents.OrderCodeExtensions} />
						<RouteManager
							permission={['licensesConfigurationList']} exact path='/licenses/configurations'
							component={asyncComponents.ConfigurationsList} />
						<RouteManager
							permission={['licenseModuleGetLicenseNumberDetail']} exact path='/licenses/license-decoder'
							component={asyncComponents.LicenseDecoder} />

						<RouteManager
							permission={['reaList']} exact path='/accounting/rea/list'
							component={asyncComponents.ReaList} />

						<RouteManager
							permission={['allPartnerReportList']} exact path='/accounting/provision/reports/list'
							component={asyncComponents.ReportList} />
						<RouteManager
							permission={['partnerReportDetail']} exact path='/accounting/provision/report/:id'
							component={asyncComponents.ReportDetail} />

						<RouteManager
							permission={['invoicesGroupsList']} exact path='/accounting/invoices/list'
							component={asyncComponents.InvoiceGroupList} />
						<RouteManager
							permission={['invoicesList']} exact path='/accounting/invoices/:id'
							component={asyncComponents.InvoiceGroupDetail} />

						<RouteManager
							permission={['inventories']} exact path='/accounting/inventories/list'
							component={asyncComponents.InventoriesList} />

						<RouteManager
							permission={['taxRulesListTaxRules']} exact path='/accounting/tax-rules/list'
							component={asyncComponents.TaxRulesList} />

						<RouteManager
							permission={['invoicesCustomersList']} exact path='/accounting/customers/list'
							component={asyncComponents.CustomerList} />

						<RouteManager
							permission={['turnoverStatistics']} exact path='/accounting/turnover'
							component={asyncComponents.Turnover} />

						<RouteManager
							permission={['invoicesCustomersDocumentsList']} exact path='/accounting/customers/documents/:id'
							component={asyncComponents.DocumentList} />
						{/* <RouteManager
							permission={['invoicesList']} exact path='/accounting/invoices/:id'
							component={asyncComponents.InvoiceGroupDetail} /> */}

						<RouteManager
							permission={['listInventoryGroup']} exact path='/accounting/inventory-group/list'
							component={asyncComponents.InventoryGroupList} />
						{/* <RouteManager
							permission={['invoicesList']} exact path='/accounting/invoices/:id'
							component={asyncComponents.InvoiceGroupDetail} /> */}

						<RouteManager
							permission={['rolesModule']} exact path='/roles/list'
							component={asyncComponents.Roles} />
						<RouteManager
							permission={['permissionsModule']} exact path='/roles/permissions'
							component={asyncComponents.Permissions} />


						<RouteManager
							permission={['emailTemplateModule']} exact path='/email-template'
							component={asyncComponents.EmailTemplates} />
						<RouteManager
							permission={['emailTemplateEdit']} path='/email-template/:id'
							component={asyncComponents.EditEmailTemplate} />

						<Route exact path='/profile' component={Profile} />

						{/* <RouteManager
							permission={['birdhubCredentialList']} exact path='/birdhub/credential/list'
							component={asyncComponents.Credential} /> */}

						<RouteManager
							permission={['phoenixCompanyList']} exact path='/phoenix/company/list'
							component={asyncComponents.Company} />
						<RouteManager
							permission={['phoenixCompanyRead']} exact path='/phoenix/company/:id'
							component={asyncComponents.CompanyDetail} />
						<RouteManager
							permission={['phoenixCompanyUpdate']} exact path='/phoenix/company/edit/:id'
							component={asyncComponents.CompanyDetail} />
						<RouteManager
							permission={['phoenixModuleList']} exact path='/phoenix/module/list'
							component={asyncComponents.ModuleList} />
						<RouteManager
							permission={['phoenixPackageList']} exact path='/phoenix/package/list'
							component={asyncComponents.PackageList} />
						<RouteManager
							permission={['phoenixApiKeyList']} exact path='/phoenix/api-key/list'
							component={asyncComponents.ApiKeyList} />

						<RouteManager
							permission={['birdhubClientsList']} exact path='/birdhub/clients/list'
							component={asyncComponents.ClientsList} />
						<RouteManager
							permission={['birdhubOrdersList']} exact path='/birdhub/clients/:client_code/orders'
							component={asyncComponents.OrdersList} />
						<RouteManager
							permission={['birdhubOrdersDetail']} exact path='/birdhub/clients/:client_code/orders/:id'
							component={asyncComponents.OrderDetail} />


						<RouteManager
							permission={['jumpbirdCompaniesList']} exact path='/jumpbird/company/list'
							component={asyncComponents.JumpbirdCompany} />
						<RouteManager
							permission={['jumpbirdCompaniesViewDetail']} exact path='/jumpbird/company/:id'
							component={asyncComponents.JumpbirdCompanyDetail} />
						<RouteManager
							permission={['jumpbirdCompaniesUpdateCompanyData']} exact path='/jumpbird/company/edit/:id'
							component={asyncComponents.JumpbirdCompanyDetail} />
						<RouteManager
							permission={['jumpbirdSubscriptionsListPackages']} exact path='/jumpbird/package/list'
							component={asyncComponents.JumpbirdPackageList} />

						<RouteManager
							permission={['resigoAdminListMachines']} exact path='/resigo/machine/list'
							component={asyncComponents.ResigoMachine} />
						<RouteManager
							permission={['resigoAdminViewMachineDetail']} exact path='/resigo/machine/:id'
							component={asyncComponents.ResigoMachineDetail} />
						<RouteManager
							permission={['resigoAdminUpdateMachineData']} exact path='/resigo/machine/edit/:id'
							component={asyncComponents.ResigoMachineDetail} />
						<RouteManager
							permission={['resigoAdminListLicenses']} exact path='/resigo/licenses/list'
							component={asyncComponents.ResigoLicense} />


						<RouteManager
							permission={['tseDeviceList']} exact path='/tse/device/list'
							component={asyncComponents.TSEDeviceList} />
						<RouteManager
							permission={['tseViewDeviceDetail', 'tseActivityLog']} exact path='/tse/device/activity/:id'
							component={asyncComponents.TSEDeviceActivityLog} />
						<RouteManager
							permission={['tseCustomerList']} exact path='/tse/universe/customer/list'
							component={asyncComponents.TSECustomerList} />
						<RouteManager
							permission={['tseCustomerRead']} exact path='/tse/universe/customer/:id'
							component={asyncComponents.TSECustomerDetail} />
						<RouteManager
							permission={['tseCustomerListV2']} exact path='/tse/customer/list'
							component={asyncComponents.TSECustomerListV2} />
						<RouteManager
							permission={['tseCustomerRead']} exact path='/tse/customer/:id'
							component={asyncComponents.TSECustomerDetail} />
						<RouteManager
							anyOnePermission={['tseBackupUploadStatisticsGraphView', 'tseCustomerStatistics']} exact path='/tse/dashboard'
							component={asyncComponents.TSEDashboard} />

						<RouteManager
							anyOnePermission={['posbillBackupUploadStatistics'/* , 'tseCustomerStatistics' */]} exact path='/posbill-backup/dashboard'
							component={asyncComponents.PosbillBackupDashboard} />
						<RouteManager
							permission={['posbillBackupCustomers']} exact path='/posbill-backup/customer/list'
							component={asyncComponents.PosbillBackupCustomerList} />
						<RouteManager
							permission={['posbillBackupCustomers']} exact path='/posbill-backup/customer/:id'
							component={asyncComponents.PosbillBackupCustomerDetail} />
						<RouteManager
							permission={['posbillBackupListDownloadRequests']} exact path='/posbill-backup/download-request/list'
							component={asyncComponents.PosbillBackupDownloadRequestList} />

						<RouteManager
							permission={['zmListGetRequests']} exact path='/tools/zm/list'
							component={asyncComponents.ZMList} />

						<RouteManager
							anyOnePermission={[
								'pbpwGetUploadFileTempCredential',
								'pbpwListPosbillPasswords',
								'pbpwGetCurrentAndNextHourPasswords',
								'pbpwGetCurrentAndNextHourPasswordsOfCustomDate',
							]} exact path='/tools/pbpw'
							component={asyncComponents.PbpwList} />

						<Route exact path='/404' component={NotFound} />
						<Redirect to='/404' />
					</Switch>
				</Suspense>
			</Layout>
		);
	} else {
		// lazy loading not needed for login, forget password, reset password APIs
		return (
			<Switch>
				<Route path='/login' component={Login} />
				<Route path='/forget-password' component={ForgetPassword} />
				<Route path='/reset-password/:token' component={ResetPassword} />
				<Redirect to='/login' />
			</Switch>
		);
	}
}

export default App;


import React from 'react';
import { Formik, FormikValues } from 'formik';

import { CustomForm } from 'shared/components/form/form';
import { Input, FieldConfig } from 'shared/components/form/inputTypes';
import localizationConstants from 'shared/util/translation/constants';
import * as schema from 'shared/constants/validation-schema';
import { Translate } from 'shared/components/translate';
import Button from 'shared/components/form/button';
import WithTranslateFormErrors from 'shared/hoc/withTranslationError';
import PasswordAssistent from 'shared/components/passwordAssistent/passwordAssistent';
import PhoenixLogo from 'assets/images/phoenix_logo_green.png';

interface Props {
	isLoading: boolean;
	isSuccessful: boolean;
	message: string;
	resetPassword: (values: FormikValues) => void;
}

const ResetPassword: React.FC<Props> = (props) => {
	const { isLoading, isSuccessful, message, resetPassword } = props;
	return (
		<div className='middle-box loginscreen animated fadeInDown'>
			<div className="ibox">
				<div className="ibox-content b-r-md">
					<img
						src={PhoenixLogo}
						style={{ width: '200px' }}
						alt="phoenix-logo"
						className="m-b-sm"
					/>
					<h2 className="font-bold">
						<Translate text={localizationConstants.resetYourPassword} />
					</h2>
					<div style={{	height: '20rem', display: 'grid',	alignItems: 'center' }}>
						{!isSuccessful ? (
							<div className='custom-form'>
								<div className='row'>
									<div className='col-lg-12'>
										<div className='ibox float-e-margins'>
											<div className='ibox-content'>
												<Formik
													initialValues={{password: ''}}
													validateOnBlur={true}
													validateOnChange={true}
													onSubmit={resetPassword}
													validationSchema={schema.phoenixResetPasswordValidationSchema}
												>
													{({	handleSubmit, setFieldValue, setFieldTouched, errors, touched, values }) => (
														<WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
															<form onSubmit={handleSubmit}>
																<fieldset className="m-t">
																	{fieldConfig.map((config, index) => (
																		<div key={index} className="form-group">
																			<Input
																				showLabels={false}
																				setFieldValue={setFieldValue}
																				config={config}
																				placeholder={config.placeHolder || config.label}
																				type={config.type}
																				name={config.name}
																			/>
																			{config.name === 'password' &&
																				<PasswordAssistent value={values.password}/>
																			}
																		</div>
																	))}
																	<div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
																		<Button
																			className="block full-width m-b m-t width-100px"
																			loading={isLoading}
																			type='submit'
																			btnType='primary'>{localizationConstants.resetPassword}</Button>
																	</div>

																</fieldset>
															</form>
														</WithTranslateFormErrors>
													)}
												</Formik>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className='validate-card-wrapper'>
								<div className='card'>
									<div className='content'>
										<div className='label-text justify-content-center success'>
											<p className='text-center'>{message}</p>
										</div>
									</div>
									<div className='content'>
										<div className='content btn-wrapper no-margins mb-3'>
											<a className='btn btn-primary ignore-text-capitalize mt-2' href={process.env.REACT_APP_PHOENIX_URL}>
												<Translate text={localizationConstants.redirectToPhoenix} />
											</a>
										</div>
									</div>
								</div>
							</div>
						)}		
					</div>
				</div>
			</div>
		</div>
	)
}

const fieldConfig: FieldConfig[] = [
	{ type: 'password', label: localizationConstants.newPassword, name: 'password' },
	{ type: 'password', label: localizationConstants.confirmPassword, name: 'password_confirmation' },
];

export default ResetPassword;

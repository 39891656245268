import React from 'react';
import { connect } from 'react-redux';
import Sidebar from 'shared/components/navigation/sidenav';
import TopHeader from 'shared/components/navigation/topHeader';
import Spinner from 'shared/components/spinner/spinner';
import { LoadingState, State } from 'shared/interface';
// import Footer from 'shared/components/footer/footer';

const Layout: React.FC<{ loading: LoadingState }> = (props) => {
	return (
		<div id='wrapper'>
			<Sidebar />
			<div id='page-wrapper' className='gray-bg'>
				<TopHeader />
				{props.loading.api['GET_PROFILE'] ? <Spinner /> : props.children}
			</div>
			{/* <Footer /> */}
		</div>
	)
}

const mapStateToProps = (state: State) => {
	return {
		loading: state.loading
	};
}

export default connect(mapStateToProps)(Layout);
